import axios from "axios";
import {API_BASE_URL} from "../constants/api";

const CertificateService = {};
CertificateService.getCertificateByCode = async function (code) {
    return axios({
        method: "get",
        url: API_BASE_URL + `/api/old-certificate/get/${code}`,
        headers: {
            "Content-Type": "application/json"
        }
    });
}

export default CertificateService;