import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import ApplicationService from "../../services/ApplicationService";

let initialState = {
    applications: [],
    isLoading: false,
    hasMore: false,
    error: null,
}
export const getAllApplicationsPageable = createAsyncThunk(
    'getAllApplicationsPageable',
    async ({ page, limit, applicationStatus, categoryId, fullName }, {getState, rejectWithValue, dispatch}) => {
        try {
            const response = await ApplicationService.getAllApplicationsPageable(page, limit, applicationStatus, categoryId, fullName);
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const getAllApplicationsByStatusPageable = createAsyncThunk(
    'getAllApplicationsByStatusPageable',
    async ({ page, limit, status }, {getState, rejectWithValue, dispatch}) => {
        try {
            const response = await ApplicationService.getAllApplicationsByStatusPageable(page, limit, status);
            return response;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

const applicationsListSlice = createSlice({
    name: 'applicationsListSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllApplicationsPageable.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllApplicationsPageable.fulfilled, (state, action) => {
                state.isLoading = false;
                state.applications = action.payload.data;
                state.hasMore = action.payload.hasMore;
            })
            .addCase(getAllApplicationsPageable.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                state.applications = [];
                state.hasMore = false;
            })
            .addCase(getAllApplicationsByStatusPageable.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllApplicationsByStatusPageable.fulfilled, (state, action) => {
                state.isLoading = false;
                state.applications = action.payload.data;
                state.hasMore = action.payload.hasMore;
            })
            .addCase(getAllApplicationsByStatusPageable.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                state.applications = [];
                state.hasMore = false;
            })
    }
});

export const {} = applicationsListSlice.actions;

export default applicationsListSlice.reducer;