import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import CategoryService from "../../services/CategoryService";
import OrderService from "../../services/OrderService";

let initialState = {
    students: [],
    isLoading: false,
    error: null,
}
export const getAllStudentsForOrder = createAsyncThunk(
    'getAllStudentsForOrder',
    async ({categoryId, searchStudent}, {getState, rejectWithValue, dispatch}) => {
        try {
            const response = await OrderService.searchStudents(categoryId, searchStudent);
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

const studentsListForOrderSlice = createSlice({
    name: 'studentsListForOrderSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllStudentsForOrder.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllStudentsForOrder.fulfilled, (state, action) => {
                state.isLoading = false;
                state.students = action.payload;
            })
            .addCase(getAllStudentsForOrder.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                state.students = [];
            })
    }
});

export const {} = studentsListForOrderSlice.actions;

export default studentsListForOrderSlice.reducer;