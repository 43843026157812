import React, {useState} from 'react';
import {Button, Flex, Form, Input, message} from "antd";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import AuthService from "../../services/AuthService";
import {getUserByToken} from "../../store/slices/userSlice";
import IntlMessage, {IntlMessageText} from "../../components/IntlMessage/IntlMessage";
import cl from './login.module.css';
import classes from '../global_styles.module.css';
import Header from "../components/Header/Header";
import back from "../../images/background_2048-03.svg";
import MyText from "../../components/UI/Text/MyText";
import FormItem from "antd/es/form/FormItem";
import {clrs} from "../../constants/colors";
import Footer from "../components/Footer/Footer";
import GreyButton from "../../components/UI/Buttons/GreyButton";

const LoginPage = () => {
    const [form] = Form.useForm();

    const username = Form.useWatch('username', form);
    const password = Form.useWatch('password', form);

    const navigate = useNavigate();
    const {user, isLoading} = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    if (user !== null && !isLoading) {
        navigate('/p/applications');
    }

    function handleOnSubmit() {
        form.validateFields()
            .then(() => {
                AuthService.login(username, password)
                    .then((result) => {
                        // console.log(result.data);
                        message.destroy();
                        message.success(<IntlMessage id={'success'}/>, 5);
                        localStorage.setItem('Authorization', result?.data.access_token);
                        localStorage.setItem('ExpirationDate', result?.data.expiration_date);
                        dispatch(getUserByToken());
                    })
                    .catch((result) => {
                        message.destroy();
                        if (result?.response.data === "user not found" || result?.response.data === "password is incorrect") {
                            message.error(<IntlMessage id={'incorrectPasswordOrUsername'}/>, 5);
                        } else {
                            message.error(<IntlMessage id={'error'}/>, 5);

                        }
                    })
            })
            .catch(() => {

            })
    }


    return (
        <div style={{position: "relative"}}>

            <Header/>
            <Flex style={{
                width: "100%",
                position: "relative",
                padding: "120px 20px 20px 20px",
                minHeight: "calc(100vh - 150px)"
            }} align={"center"} justify={"center"}>
                <div className={cl.backImage} style={{backgroundImage: `url(${back})`}}>
                    {/*<img src={back} alt=""/>*/}
                </div>
                <Flex gap={50} style={{
                    width: "100%",
                    maxWidth: 500,
                    position: "relative",
                    backgroundColor: "white",
                    padding: "70px 20px",
                    border: `2px solid ${clrs.whiter}`,
                    borderRadius: 20
                }} vertical align={"center"}
                      justify={"center"}>
                    <MyText size={"large"} strong uppercase>
                        <IntlMessage id={'login'}/>
                    </MyText>
                    <Form onKeyUp={(e) => {if (e.key === "Enter") {handleOnSubmit()}}} form={form} className={classes.form} style={{justifyContent: "center"}}>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                        ]} className={classes.formInputBlock} name="username">
                            <Input rootClassName={classes.formInput}
                                   placeholder={IntlMessageText.getText({id: "username"})}/>
                        </FormItem>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                        ]} className={classes.formInputBlock} name="password">
                            <Input.Password rootClassName={classes.formInput}
                                            placeholder={IntlMessageText.getText({id: "password"})}/>
                        </FormItem>
                        <FormItem className={classes.formInputBlock}>
                            <Flex style={{width: "100%"}} align={"center"} justify={"center"}>
                                <GreyButton
                                    onClick={handleOnSubmit}
                                    backgroundColor={clrs.blackerBlue}
                                    fontColor={clrs.white}>
                                    <IntlMessage id={'login'}/>
                                </GreyButton>
                            </Flex>
                        </FormItem>
                    </Form>
                </Flex>
            </Flex>
            <Footer/>
        </div>
    );
};

export default LoginPage;