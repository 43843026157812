import React, {useEffect, useRef, useState} from 'react';
import classes from './PlatformHeader.module.css';
import burger from '../../../../images/burger-menu-5.svg';
import {useDispatch, useSelector} from "react-redux";
import {toggleClosed} from "../../../../store/slices/platform/PlatformNavSlice";
import {useWindowSize} from "@uidotdev/usehooks";


const PlatformHeader = () => {
    const dispatch = useDispatch();
    const size = useWindowSize();
    const headerRef = useRef(null);
    // const [width, setWidth] = useState(0);
    const isClosedNav = useSelector(state => state.platformNav.isClosed);

    function handleClose() {
        dispatch(toggleClosed())
    }

    return (
        <div ref={headerRef} className={classes.header}>
            <div className={classes.wrapper}>
                <div className={classes.navIconBlock} onClick={handleClose}>
                    <img src={burger} alt=""/>
                </div>
            </div>
        </div>
    );
};

export default PlatformHeader;