import React from 'react';
import {Image} from "antd";
import logo from "../../../images/CCE_logo_white.svg";
import {Link} from "react-router-dom";

const LogoWhite = () => {
    return (
        <Link to={'/'}>
            <Image preview={false} width={100} src={logo}/>
        </Link>
    );
};

export default LogoWhite;