import React, {useEffect, useState} from 'react';
import {Flex, Form, Input, message, Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getAllGlobalValues} from "../../../../../store/slices/GlobalValueListSlice";
import classes from "./style.module.css";
import FormItem from "antd/es/form/FormItem";
import {clrs} from "../../../../../constants/colors";
import GlobalValueService from "../../../../../services/GlobalValueService";
import {LoadingOutlined} from "@ant-design/icons";
import IntlMessage from "../../../../../components/IntlMessage/IntlMessage";
import GreyButton from "../../../../../components/UI/Buttons/GreyButton";

const GlobalValueList = () => {
    const dispatch = useDispatch();

    const globalValueList = useSelector(state => state.globalValueList);

    const [forms, setForms] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const [isLoadingInit, setIsLoadingInit] = useState(false);

    const [form] = Form.useForm();

    useEffect(() => {
        dispatch(getAllGlobalValues())
    }, []);

    useEffect(() => {
        if (globalValueList.globalValues != null) {
            setIsLoadingInit(true);
            setInitialValues({});
            globalValueList.globalValues.forEach(globalValue => {
                let newValues = {
                    [globalValue.id + "-name"]: globalValue.name,
                    [globalValue.id + "-type"]: globalValue.type,
                    [globalValue.id + "-value"]: globalValue.value,
                }
                setInitialValues((prevValues) => ({...prevValues, ...newValues}));
            })
            setTimeout(() => {
                setIsLoadingInit(false);
            }, 0);
        }
    }, [globalValueList.globalValues]);

    async function handleSaveGlobalValue(id) {
        // const value = Form.useWatch(id + '-value', form);
        const value = form.getFieldValue(id + '-value');
        await GlobalValueService.updateValue(id, value)
            .then(() => {
                message.success(<IntlMessage id={'saved'}/>, 5);
                dispatch(getAllGlobalValues())
            })
            .catch(() => {
                message.error(<IntlMessage id={'error'}/>, 5)
            })

    }

    if (isLoadingInit) {
        return (
            <Flex vertical align={"center"} justify={"center"} gap={20} style={{width: "100%"}}>
                <Flex wrap={"wrap"} align={"flex-start"} justify={"flex-start"} gap={20} style={{width: "100%"}}>
                    <GreyButton onClick={() => {
                        dispatch(getAllGlobalValues());
                    }} backgroundColor={clrs.whiter} fontColor={clrs.black}>
                        <IntlMessage id={'update'}/>
                    </GreyButton>
                </Flex>
                <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>

            </Flex>
        );
    }

    return (
        <Flex vertical align={"center"} justify={"center"} gap={20} style={{width: "100%"}}>
            <Flex wrap={"wrap"} align={"flex-start"} justify={"flex-start"} gap={20} style={{width: "100%"}}>
                <GreyButton onClick={() => {
                    dispatch(getAllGlobalValues());
                }} backgroundColor={clrs.whiter} fontColor={clrs.black}>
                    <IntlMessage id={'update'}/>
                </GreyButton>
            </Flex>
            <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>} spinning={globalValueList.isLoading}/>
            <Form form={form} className={classes.form} initialValues={initialValues}>
                {
                    globalValueList.globalValues.map((globalValue, i) => {
                        return (
                            <Flex align={"flex-start"} justify={"flex-start"} wrap={"wrap"} gap={20} key={globalValue.id} style={{width: "100%"}}>
                                <FormItem className={classes.formInputBlock} name={globalValue.id + '-name'}>
                                    <Input disabled={true} rootClassName={classes.formInput}/>
                                </FormItem>
                                <FormItem className={classes.formInputBlock} style={{maxWidth: "150px"}}
                                          name={globalValue.id + '-type'}>
                                    <Input disabled={true} rootClassName={classes.formInput}/>
                                </FormItem>
                                <FormItem rules={[{
                                    required: true,
                                    message: <IntlMessage id={'requiredField'}/>,
                                },
                                ]} className={classes.formInputBlock} name={globalValue.id + '-value'}>
                                    <Input rootClassName={classes.formInput}/>
                                </FormItem>
                                <FormItem className={classes.formInputBlock}>
                                    <Flex style={{width: "100%"}} gap={20} align={"flex-start"} justify={"flex-start"}>
                                        <GreyButton onClick={() => {
                                            handleSaveGlobalValue(globalValue.id)
                                        }} backgroundColor={clrs.blackerBlue} fontColor={clrs.white}>
                                            <IntlMessage id={'save'}/>
                                        </GreyButton>
                                    </Flex>
                                </FormItem>
                            </Flex>
                        )
                    })
                }
            </Form>
        </Flex>
    );
};

export default GlobalValueList;