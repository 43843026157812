import {ACCESS_TOKEN} from "../constants/auth";
import {API_BASE_URL} from "../constants/api";
import axios from "axios";

const CategoryService = {};
CategoryService.saveCategory = async function (nameEng, nameRu, nameKz, formType) {
    let data = new FormData();
    data.append("nameEng", nameEng);
    data.append("nameRu", nameRu);
    data.append("nameKz", nameKz);
    if (formType !== undefined) {
        data.append("formType", formType);
    }


    return axios({
        method: "post",
        url: API_BASE_URL + "/api/admin/category/save",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
CategoryService.getAllCategories = async function () {
    return axios({
        method: "get",
        url: API_BASE_URL + `/api/category/get`,
        headers: {
            "Content-Type": "application/json"
        }
    });
}

CategoryService.deleteCategoryById = async function (id) {
    return axios({
        method: "delete",
        url: API_BASE_URL + `/api/admin/category/delete/${id}`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}


export default CategoryService;