import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import CategoryService from "../../services/CategoryService";

let initialState = {
    data: null,
    isLoading: false,
    error: null,
}

export const saveCategory = createAsyncThunk(
    'saveCategory',
    async ({nameEng, nameRu, nameKz, formType}, {getState, rejectWithValue, dispatch}) => {
        try {
            const response = await CategoryService.saveCategory(nameEng, nameRu, nameKz, formType);
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

const categorySlice = createSlice({
    name: 'categorySlice',
    initialState,
    reducers: {
        resetCategoryState(state) {
            state.data = null;
            state.isLoading = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveCategory.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.data = null;
            })
            .addCase(saveCategory.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload.data;
            })
            .addCase(saveCategory.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});

export const {resetCategoryState} = categorySlice.actions;

export default categorySlice.reducer;