import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";

const GlobalValueService = {};
GlobalValueService.getAllGlobalValues = async function () {
    return axios({
        method: "get",
        url: API_BASE_URL + `/api/admin/global-value/get`,
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

GlobalValueService.updateValue = async function (id, value) {
    let data = new FormData();
    data.append("value", value);

    return axios({
        method: "put",
        url: API_BASE_URL + `/api/admin/global-value/update/${id}`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
GlobalValueService.saveGlobalValue = async function (name, type, value) {
    let data = new FormData();
    data.append("name", name);
    data.append("type", type);
    data.append("value", value);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/admin/global-value/save",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
export default GlobalValueService;