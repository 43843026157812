import React, {useEffect} from 'react';
import classes from './PlatformNav.module.css';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useWindowSize} from "@uidotdev/usehooks";
import {useDispatch} from "react-redux";
import {setClosed} from "../../../../store/slices/platform/PlatformNavSlice";

const NavItem = ({icon, text, to, disabled, loc}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const location = useLocation();

    const size = useWindowSize();

    function handleOnClick() {
        if (size.width <= 1024) {
            dispatch(setClosed(true));
        }
    }

    return (
        <Link onClick={handleOnClick} located={location.pathname.startsWith(loc).toString()} className={[classes.item, disabled && classes.disabledItem].join(" ")} to={disabled ? null : to}>
            {icon}
            <div className={classes.itemText}>{text}</div>
        </Link>
    );
};

export default NavItem;