import React from 'react';
import classes from './PlatformNav.module.css';
import NavMenu from "./NavMenu";

const PlatformNavigation = () => {
    return (
        <div className={classes.main}>
            {/*<Link to={'/'} className={classes.navHeader}>*/}
            {/*    /!*<img src={logo} alt=""/>*!/*/}
            {/*    <p style={{fontSize: "2rem", color: "white", fontWeight: 900}}>O.U.</p>*/}
            {/*</Link>*/}
            <NavMenu/>
        </div>
    );
};

export default PlatformNavigation;