import React from 'react';
import BannerForm from "./BannerForm";
import classes from './Banner.module.css';
import back from '../../../../images/background_2048-01.svg';
import {useWindowSize} from "@uidotdev/usehooks";
import {Fade} from "react-awesome-reveal";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";
import {Flex, Image} from "antd";
import cl from '../../../global_styles.module.css';

const Banner = () => {
    const size = useWindowSize();

    return (
        <Flex style={{position: "relative", width: "100%"}} gap={50} vertical>
            <Image wrapperStyle={{
                width: "100%",
                position: "absolute",
                height: "100%",
                top: 0,
                zIndex: -1,
            }} preview={false} src={back} style={{backgroundPosition: "center"}}/>
            <Flex style={{width: "100%", position: "relative"}} align={"center"} justify={"space-between"} gap={20} vertical={size.width <= 1024} >
                <Fade triggerOnce={true} cascade>
                    <p className={classes.bannerText}>
                        <IntlMessage id={'bannerText1'}/>
                    </p>
                </Fade>
                <Fade triggerOnce={true} cascade className={classes.fadeBlock}>
                    <BannerForm/>
                </Fade>

            </Flex>

            <Flex style={{
                width: "100%",
                borderRadius: 0,
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                backgroundColor: "white",
                paddingTop: 35,
                paddingBottom: 35,
                textAlign: "center"
            }} justify={size.width <= 1024 ? "center" : "space-between"} wrap={size.width <= 1024} gap={20} align={"center"}>
                <Fade triggerOnce={true} cascade damping={0.15} direction={"up"}>
                    <p><IntlMessage id={'underBannerText1'}/></p>
                    <p><IntlMessage id={'underBannerText2'}/></p>
                    <p><IntlMessage id={'underBannerText3'}/></p>
                    <p><IntlMessage id={'underBannerText4'}/></p>
                    <p><IntlMessage id={'underBannerText5'}/></p>
                </Fade>
            </Flex>
        </Flex>
    );
};

export default Banner;