import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import FormService from "../../services/FormService";

let initialState = {
    form: null,
    isLoading: true,
    error: null,
}

export const getFormById = createAsyncThunk(
    'getFormById',
    async ({formId}, { getState, rejectWithValue, dispatch }) => {
        try {
            const response = await FormService.getFormById(formId);
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

const formSlice = createSlice({
    name: 'formSlice',
    initialState,
    reducers: {
        setForm(state, action) {
            state.form = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFormById.pending, (state) => {
                state.isLoading = true;
                state.form = null;
                state.error = null;
            })
            .addCase(getFormById.fulfilled, (state, action) => {
                state.form = action.payload;
                state.isLoading = false;
            })
            .addCase(getFormById.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});

export const {
    setForm
} = formSlice.actions;

export default formSlice.reducer;