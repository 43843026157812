import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";

const TeacherService = {};
TeacherService.getAllTeachers = async function (page, limit, fullName, categoryId) {
    if (categoryId === undefined) {
        categoryId = null;
    }
    if (fullName === undefined) {
        fullName = null;
    }
    const response = await axios({
        method: "get",
        url: API_BASE_URL + `/api/admin/teacher/get`,
        params: {
            page: page - 1,
            limit: limit,
            categoryId: categoryId,
            fullName: fullName,
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
    const data = response.data.list;
    const hasMore = response.data.hasMore;
    return { data, hasMore };
}

TeacherService.getAllTeachersByCategory = async function (categoryId) {
    if (categoryId === undefined) {
        categoryId = null;
    }
    return axios({
        method: "get",
        url: API_BASE_URL + `/api/admin/teacher/get/all/by-category`,
        params: {
            categoryId: categoryId,
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

TeacherService.saveTeacher = async function (fullName, categoryId) {
    let data = new FormData();
    data.append("fullName", fullName);
    data.append("categoryId", categoryId);

    return axios({
        method: "post",
        url: API_BASE_URL + `/api/admin/teacher/save`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}
export default TeacherService;