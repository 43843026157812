import React, {useState} from 'react';
import classes from './header.module.css';
import LogoBlack from "../Logo/LogoBlack";
import {Drawer, Flex, Image} from "antd";
import GreyButton from "../../../components/UI/Buttons/GreyButton";
import IntlMessage from "../../../components/IntlMessage/IntlMessage";
import {Link, useNavigate} from "react-router-dom";
import {useWindowSize} from "@uidotdev/usehooks";
import burger from '../../../images/burger-menu-5.svg';
const Header = () => {
    const [open, setOpen] = useState(false);
    const size = useWindowSize();

    const navigate = useNavigate();

    if (size.width <= 768) {
        return (
            <div className={classes.header}>
                <div className={classes.wrapper}>
                    <Image preview={false} width={40} src={burger} onClick={() => {setOpen(true)}} wrapperStyle={{cursor: "pointer"}}/>
                    <Drawer width={300} placement={'left'} open={open} onClose={() => {setOpen(false)}}>
                        <Flex style={{width: "100%"}} gap={35} vertical align={"center"} justify={"center"}>
                            <LogoBlack/>
                            <Flex gap={30} align={"center"} vertical justify={"center"}>
                                <Link to={'/contacts'} className={classes.hoverText}>
                                    <IntlMessage id={'contacts'}/>
                                </Link>
                                <Link to={'/certificates'} className={classes.hoverText}>
                                    <IntlMessage id={'certificates'}/>
                                </Link>
                                <Link to={'/login'}>
                                    <GreyButton>
                                        <IntlMessage id={'login'}/>
                                    </GreyButton>
                                </Link>

                            </Flex>
                        </Flex>
                    </Drawer>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.header}>
            <div className={classes.wrapper}>
                <LogoBlack/>
                <Flex gap={30} align={"center"} justify={"center"}>
                    <Link to={'/contacts'} className={classes.hoverText}>
                        <IntlMessage id={'contacts'}/>
                    </Link>
                    <Link to={'/certificates'} className={classes.hoverText}>
                        <IntlMessage id={'certificates'}/>
                    </Link>
                    <Link to={'/login'}>
                        <GreyButton>
                            <IntlMessage id={'login'}/>
                        </GreyButton>
                    </Link>
                </Flex>
            </div>
        </div>
    );
};

export default Header;