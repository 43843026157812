import React from 'react';
import {Flex, message, Tabs} from "antd";
import {useDispatch} from "react-redux";
import {getAllCategories, getAllCategoriesPageable} from "../../../../store/slices/CategoriesListSlice";
import CoursesList from "./List/CoursesList";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";
import MyText from "../../../../components/UI/Text/MyText";
import CreateCategory from "./CreateCategory/CreateCategory";
import CreateCourse from "./CreateCourse/CreateCourse";

const CoursesPage = () => {
    const dispatch = useDispatch();

    const onChangeTabs = async (key) => {
        if (key === '1') {
            await dispatch(getAllCategories());
        }
        if (key === '3') {
            await dispatch(getAllCategories());
        }
    };

    const tabItems = [
        {
            key: '1',
            label: <IntlMessage id={'list'}/>,
            children: <CoursesList/>,
        },
        {
            key: '2',
            label: <IntlMessage id={'createCategory'}/>,
            children: <CreateCategory/>,
        },
        {
            key: '3',
            label: <IntlMessage id={'createCourse'}/>,
            children: <CreateCourse/>,
        },
    ]

    return (
        <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} vertical gap={20}>
            <MyText uppercase strong size={"large"}><IntlMessage id={'courses'}/></MyText>
            <Tabs style={{width: "100%"}} defaultActiveKey={"1"} type="card" items={tabItems} onChange={onChangeTabs}/>
        </Flex>
    );
};

export default CoursesPage;