import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import CategoryService from "../../services/CategoryService";
import OrderService from "../../services/OrderService";

let initialState = {
    realGroups: [],
}

const realGroupsForOrderSlice = createSlice({
    name: 'realGroupsForOrderSlice',
    initialState,
    reducers: {
        addGroups(state, action) {
            state.realGroups = action.payload;
        },
    },
});

export const {addGroups} = realGroupsForOrderSlice.actions;

export default realGroupsForOrderSlice.reducer;