import React from 'react';
import {Button} from "antd";
import classes from "./buttons.module.css";

const RedButton = (props) => {
    return (
        <Button {...props} className={classes.redBtn}>
            <span>{props.children}</span>
        </Button>
    );
};

RedButton.propTypes = {
    ...Button.propTypes, // Use Ant Design Button propTypes
};

export default RedButton;