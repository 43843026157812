import React from 'react';
import classes from './Footer.module.css';
import {Flex} from "antd";
import HorizontalDivider from "../../../components/Divider/HorizontalDivider";
import {clrs} from "../../../constants/colors";
import LogoWhite from "../Logo/LogoWhite";
const Footer = () => {
    return (
        <div className={classes.mainBlock}>
            <Flex align={"center"} vertical justify={"center"} gap={30} style={{width: "100%", maxWidth: 1440, position: "relative"}}>
                <HorizontalDivider style={{backgroundColor: clrs.whiterBlacker}}/>
                <Flex vertical gap={20}>
                    <LogoWhite />
                    <p>© CCE, 2024</p>
                </Flex>
            </Flex>
        </div>
    );
};

export default Footer;