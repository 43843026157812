import React, {useEffect, useState} from 'react';
import classes from '../../../global_styles.module.css';
import cl from './Banner.module.css';
import {Button, Flex, Form, Input, message, Select} from "antd";
import FormItem from "antd/es/form/FormItem";
import {useDispatch, useSelector} from "react-redux";
import {useWindowSize} from "@uidotdev/usehooks";
import CategoryService from "../../../../services/CategoryService";
import ApplicationService from "../../../../services/ApplicationService";
import {LocalName} from "../../../../utils/LocalName";
import IntlMessage, {IntlMessageText} from "../../../../components/IntlMessage/IntlMessage";
import BlockLoading from "../../../../components/LoadingComponents/BlockLoading";
import {clrs} from "../../../../constants/colors";
import GreyButton from "../../../../components/UI/Buttons/GreyButton";
import {EmailValidator} from "../../../../utils/EmailValidator";
import {useTranslation} from "react-i18next";
import InputMask from "react-input-mask";

const BannerForm = () => {
    const size = useWindowSize();

    const [form] = Form.useForm();
    const name = Form.useWatch('name', form);
    const email = Form.useWatch('email', form);
    const telNumber = Form.useWatch('telNumber', form);
    const categoryId = Form.useWatch('categoryId', form);

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const [categoriesList, setCategoriesList] = useState([]);
    const [categories, setCategories] = useState([]);

    const [loading, setLoading] = useState(true);

    function handleSubmit() {
        form.validateFields()
            .then(async () => {
                message.loading(<IntlMessage id={'loading'}/>, 0);
                setLoading(true);
                await ApplicationService.saveApplication(
                    name,
                    email,
                    telNumber.endsWith('_') ? telNumber.slice(0, -1) : telNumber,
                    categoryId)
                    .then(() => {
                        message.destroy();
                        message.success(<IntlMessage id={'successApplicationSend'}/>, 5);
                        form.resetFields();
                        setLoading(false);
                    })
                    .catch(() => {
                        message.destroy();
                        message.error(<IntlMessage id={'error'}/>, 5);
                        setLoading(false);
                    })
            })
            .catch(() => {
                console.log("Validation error!")
            })
    }

    useEffect(() => {
        CategoryService.getAllCategories()
            .then((r) => {
                setLoading(false);
                setCategoriesList(r.data);
            })
            .catch(() => {
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        setCategories(
            categoriesList.map(category => {
                return { value: category.id, label: LocalName.getName(category) }
            })
        )
    }, [categoriesList]);

    return (
        <Form form={form} onSubmit={handleSubmit} className={[cl.bannerFormBlock, classes.form].join(" ")}>
            <BlockLoading isLoading={loading}/>
            <div>
                <p className={cl.bannerFormText}><IntlMessage id={'bannerText2'}/></p>
                <p className={cl.bannerFormText}><IntlMessage id={'bannerText3'}/></p>
            </div>

            <FormItem rules={[{
                    required: true,
                    message: <IntlMessage id={'bannerFormNameError'}/>,
                },
            ]} className={classes.formInputBlock} name="name">
                <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: 'name'})}/>
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'bannerFormEmailError'}/>,
            },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (EmailValidator.isValid(value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error(t('emailRules')));
                    },
                }),
            ]} className={classes.formInputBlock} name="email">
                <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: 'email'})}/>
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'bannerFormTelError'}/>,
            },
            ]} className={classes.formInputBlock} name="telNumber">
                <InputMask
                    mask="+9 (999) 999-9999"
                    className={classes.formInput}
                    placeholder={IntlMessageText.getText({id: 'telNumber'})}
                >
                    {(inputProps) => <Input {...inputProps} />}
                </InputMask>
                {/*<Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: 'telNumber'})}/>*/}
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} className={classes.formInput} style={{width: "100%", padding: 0}} name="categoryId">
                <Select
                    options={categories}
                    placeholder={<IntlMessage id={'categoryCourse'}/>}
                />
            </FormItem>
            <FormItem className={classes.formInputBlock}>
                <Flex style={{width: "100%"}} align={"center"} justify={"center"}>
                    <GreyButton onClick={handleSubmit} backgroundColor={clrs.blackerBlue} fontColor={clrs.white}>
                        <IntlMessage id={'send'}/>
                    </GreyButton>
                </Flex>
            </FormItem>
        </Form>
    );
};

export default BannerForm;