import React from 'react';
import {Typography} from "antd";
import PropTypes from "prop-types";

const MyText = ({ uppercase, strong, italic, size, children, ...rest}) => {
    const style = {
        ...(uppercase && { textTransform: "uppercase" }),
        ...(strong && { fontWeight: "700" }),
        ...(italic && { fontStyle: "italic" }),
        ...(size === "small" && { fontSize: "0.85rem" }),
        ...(size === "default" && { fontSize: "1rem" }),
        ...(size === "large" && { fontSize: "1.5rem" }),
        ...(!size && { fontSize: "1rem" }),
        ...rest.style
    };

    return (
        <p {...rest} style={style}>
            {children}
        </p>
    );
};
MyText.propTypes = {
    ...Typography.Text.propTypes,
    uppercase: PropTypes.bool,
    strong: PropTypes.bool,
    italic: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'default', 'large']),
    children: PropTypes.node,
};

export default MyText;