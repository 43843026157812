import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import GlobalValueService from "../../services/GlobalValueService";

let initialState = {
    globalValues: [],
    isLoading: false,
    error: null,
}
export const getAllGlobalValues = createAsyncThunk(
    'getAllGlobalValues',
    async (_, {getState, rejectWithValue, dispatch}) => {
        try {
            const response = await GlobalValueService.getAllGlobalValues();
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

const globalValueListSlice = createSlice({
    name: 'globalValueListSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllGlobalValues.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllGlobalValues.fulfilled, (state, action) => {
                state.isLoading = false;
                state.globalValues = action.payload;
            })
            .addCase(getAllGlobalValues.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                state.globalValues = [];
            })
    }
});

export const {} = globalValueListSlice.actions;

export default globalValueListSlice.reducer;