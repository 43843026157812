import React from 'react';
import Header from "../components/Header/Header";
import {Flex} from "antd";
import Banner from "./components/Banner/Banner";
import StatsSection from "./components/StatsSection/StatsSection";
import PartnersSection from "./components/PartnersSection/PartnersSection";
import Footer from "../components/Footer/Footer";

const HomePage = () => {
    return (
        <div>
            <Header/>
            <Flex style={{width: "100%", position: "relative", padding: "120px 20px 20px 20px"}} align={"center"} justify={"center"}>
                <Flex gap={50} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical align={"flex-start"} justify={"flex-start"}>
                    <Banner/>
                    <StatsSection/>
                    <PartnersSection/>
                </Flex>
            </Flex>
            <Footer/>
        </div>
    );
};

export default HomePage;