import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import CourseService from "../../services/CourseService";

let initialState = {
    data: null,
    isLoading: false,
    error: null,
}

export const saveCourse = createAsyncThunk(
    'saveCourse',
    async ({nameEng, nameRu, nameKz, categoryId, numberOfHours, price}, {getState, rejectWithValue, dispatch}) => {
        try {
            const response = await CourseService.saveCourse(nameEng, nameRu, nameKz, categoryId, numberOfHours, price);
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

const courseSlice = createSlice({
    name: 'courseSlice',
    initialState,
    reducers: {
        resetCourseState(state) {
            state.data = null;
            state.isLoading = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveCourse.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.data = null;
            })
            .addCase(saveCourse.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload.data;
            })
            .addCase(saveCourse.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});

export const {resetCourseState} = courseSlice.actions;

export default courseSlice.reducer;