import React from 'react';
import classes from './StatsSection.module.css';
import Stats from "./Stats";
import {useWindowSize} from "@uidotdev/usehooks";
import {Fade, Slide} from "react-awesome-reveal";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";
import {Flex} from "antd";

const StatsSection = () => {
    const size = useWindowSize();

    return (
        <div className={classes.main}>
            <Flex align={"center"} justify={"space-between"} gap={25} vertical={size.width <= 1024}
                  style={{
                      width: "100%",
                      position: "relative",
                      padding: size.width > 1024 ? 50 : "50px 10px",
                  }}>
                <Fade triggerOnce={true} cascade>
                    <p className={classes.headerText}>
                        <IntlMessage id={'statsSectionText1'}/>
                        <br/>
                        <br/>
                        <IntlMessage id={'statsSectionText2'}/>
                    </p>
                </Fade>

                <Stats/>
            </Flex>
        </div>
    );
};

export default StatsSection;