import React from 'react';
import classes from './StatsSection.module.css';

const StatItem = ({header, body}) => {
    return (
        <div className={classes.statItem}>
            <p className={classes.statItemHeader}>{header}</p>
            <p className={classes.statItemBody}>{body}</p>
        </div>
    );
};

export default StatItem;