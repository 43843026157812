import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import FullLoading from "../../../../components/LoadingComponents/FullLoading";
import {clearUser} from "../../../../store/slices/userSlice";

const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(clearUser());
        localStorage.removeItem('Authorization');
        localStorage.removeItem('ExpirationDate');
    }, [navigate])

    return (
        <FullLoading/>
    );
};

export default Logout;