import React from 'react';
import {Form, Input, Select, message, Flex} from 'antd';
import { clrs } from '../../../../../constants/colors';
import GlobalValueService from "../../../../../services/GlobalValueService";
import FormItem from 'antd/es/form/FormItem';
import classes from '../GlobalValueList/style.module.css';
import IntlMessage, {IntlMessageText} from "../../../../../components/IntlMessage/IntlMessage";
import GreyButton from "../../../../../components/UI/Buttons/GreyButton";


const GlobalValueCreate = () => {
    const [form] = Form.useForm();

    const name = Form.useWatch('name', form);
    const type = Form.useWatch('type', form);
    const value = Form.useWatch('value', form);

    const { Option } = Select;

    function handleSubmit() {
        form.validateFields()
            .then(async () => {
                message.loading(<IntlMessage id={'loading'}/>, 0);

                await GlobalValueService.saveGlobalValue(
                    name,
                    type,
                    value
                )
                    .then(() => {
                        message.destroy();
                        message.success(<IntlMessage id={'success'}/>, 5);
                        form.resetFields();
                    })
                    .catch(() => {
                        message.destroy();
                        message.error(<IntlMessage id={'error'}/>, 5);
                    });
            })
            .catch(() => {
                console.log("Validation error!");
            });
    }

    return (
        <Form form={form} className={classes.form}>
            <FormItem rules={[{ required: true, message: <IntlMessage id={'requiredField'}/>}]} className={classes.formInputBlock2} name="name">
                <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "name2"})} />
            </FormItem>
            <FormItem rules={[{ required: true, message: <IntlMessage id={'requiredField'}/>}]} className={classes.formInputBlock2} name="type">
                <Select placeholder={"Выберите тип"}>
                    <Option value="STRING">String</Option>
                    <Option value="BOOLEAN">Boolean</Option>
                    <Option value="INT">Int</Option>
                    <Option value="DATE">Date</Option>
                    <Option value="DATETIME">Datetime</Option>
                    <Option value="DOUBLE">Double</Option>
                </Select>
            </FormItem>
            <FormItem rules={[{ required: true, message: <IntlMessage id={'requiredField'}/>}]} className={classes.formInputBlock2} name="value">
                <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "value"})} />
            </FormItem>
            <FormItem className={classes.formInputBlock2}>
                <Flex align={"center"} justify={"center"} gap={20} style={{width: "100%"}}>
                    <GreyButton onClick={handleSubmit} backgroundColor={clrs.blackerBlue} fontColor={clrs.white}>
                        <IntlMessage id={'save'}/>
                    </GreyButton>
                </Flex>
            </FormItem>
        </Form>
    );
};

export default GlobalValueCreate;