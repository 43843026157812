import React from 'react';
import {Flex, Image} from "antd";
import logo from '../../../images/CCE_logo_black.svg';
import {Link} from "react-router-dom";
const LogoBlack = () => {
    return (
        <Link to={'/'}>
            <Image preview={false} width={100} src={logo}/>
        </Link>
    );
};

export default LogoBlack;