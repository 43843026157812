export class LocalName {
    static getName(item) {
        if (localStorage.getItem('lan') === "РУС") {
            return item?.nameRu;
        }
        if (localStorage.getItem('lan') === "ҚАЗ") {
            return item?.nameKz;
        }
        return item?.nameEng;
    }

    static getDescription(item) {
        if (localStorage.getItem('lan') === "РУС") {
            return item?.descriptionRu;
        }
        if (localStorage.getItem('lan') === "ҚАЗ") {
            return item?.descriptionKz;
        }
        return item?.descriptionEng;
    }
}

// const LocalizationText = ({ text }) => {
//     const { i18n } = useTranslation();
//     const currentLocale = i18n.language;
//
//     if (currentLocale === 'kk') {
//         return <span>{text.nameKZ}</span>;
//     } else if (currentLocale === 'ru') {
//         return <span>{text.name}</span>;
//     } else return <></>;
// };
//
// export default LocalizationText;