import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import ApplicationService from "../../services/ApplicationService";

let initialState = {
    data: null,
    isLoading: false,
    error: null,
}

export const saveApplication = createAsyncThunk(
    'saveApplication',
    async ({name, email, telNumber,categoryId}, {getState, rejectWithValue, dispatch}) => {
        try {
            const response = await ApplicationService.saveApplication(name, email, telNumber, categoryId);
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const rejectApplicationById = createAsyncThunk(
    'rejectApplicationById',
    async ({id}, {getState, rejectWithValue, dispatch}) => {
        try {
            const response = await ApplicationService.rejectApplicationById(id);
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

const applicationSlice = createSlice({
    name: 'applicationSlice',
    initialState,
    reducers: {
        resetApplicationState(state) {
            state.data = null;
            state.isLoading = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveApplication.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.data = null;
            })
            .addCase(saveApplication.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload.data;
            })
            .addCase(saveApplication.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(rejectApplicationById.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.data = null;
            })
            .addCase(rejectApplicationById.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(rejectApplicationById.rejected, (state, action) => {
                state.isLoading = false;
            })
    }
});

export const {resetApplicationState} = applicationSlice.actions;

export default applicationSlice.reducer;