import React, {useEffect} from 'react';
import {Collapse, Divider, Flex, message, Modal, Popconfirm, Table} from "antd";
import classes from './courseList.module.css';
import {useDispatch, useSelector} from "react-redux";
import {getAllCategories} from "../../../../../store/slices/CategoriesListSlice";
import {LocalName} from "../../../../../utils/LocalName";
import {QuestionCircleOutlined} from "@ant-design/icons";
import CourseService from "../../../../../services/CourseService";
import CategoryService from "../../../../../services/CategoryService";
import IntlMessage from "../../../../../components/IntlMessage/IntlMessage";
import {useTranslation} from "react-i18next";


const { confirm } = Modal;
const CoursesList = () => {
    const categoriesList = useSelector(state => state.categoriesList);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getAllCategories());
    }, []);

    const handleDeleteCourse = async (id) => {
        await CourseService.deleteCourseById(id)
            .then(() => {
                message.success(<IntlMessage id={'courseDeleted'}/>, 5);
                // resolve(null); // Resolve the outer Promise after success
            })
            .catch(() => {
                message.error(<IntlMessage id={'error'}/>, 5);
                // reject(null); // Resolve the outer Promise after error
            })
            .finally(async () => {
                await dispatch(getAllCategories());
            });
    }

    const handleDeleteCategory = async (id) => {
        await CategoryService.deleteCategoryById(id)
            .then(() => {
                message.success(<IntlMessage id={'categoryDeleted'}/>, 5);
            })
            .catch(() => {
                message.error(<IntlMessage id={'error'}/>, 5);
            })
            .finally(async () => {
                await dispatch(getAllCategories());
            });
    }

    return (
        <Flex style={{width: "100%"}} vertical gap={20}>
            <Divider className={classes.divider} orientation="left"><IntlMessage id={'categories'}/></Divider>
            {
                categoriesList.categories.map((category, i) => {
                    return (
                        <Collapse
                            key={new Date() + i}
                            className={classes.eachCollapse}
                            size="small"
                            items={[
                                {
                                    key: '1',
                                    label: LocalName.getName(category),
                                    children: <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} vertical gap={20}>
                                        <Popconfirm
                                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                            title={<IntlMessage id={'deleteCategory'}/>}
                                            description={<IntlMessage id={'doYouWantDelete'}/>}
                                            onConfirm={()=> {handleDeleteCategory(category.id)}}
                                            className={classes.deleteBlock}
                                            okText={<IntlMessage id={'yes'}/>}
                                            cancelText={<IntlMessage id={'no'}/>}
                                        >
                                            <p className={classes.deleteBtn}><IntlMessage id={'delete'}/></p>
                                        </Popconfirm>
                                        <Divider className={classes.divider} orientation="left"><IntlMessage id={'courses'}/></Divider>
                                        <Table
                                            style={{width: "100%"}}
                                            columns={[
                                                {
                                                    title: <IntlMessage id={'name2'}/>,
                                                    render: (_, record) => <p>{LocalName.getName(record)}</p>
                                                },
                                                {
                                                    title: <IntlMessage id={'numberOfHours'}/>,
                                                    render: (_, record) => <p>{record.numberOfHours}</p>,
                                                    width: '15%',
                                                },
                                                {
                                                    title: t('price') + " (₸)",
                                                    render: (_, record) => <p>{record.price}</p>,
                                                    width: '15%',
                                                },
                                                {
                                                    title: <IntlMessage id={'actions'}/>,
                                                    render: (_, record) => <Popconfirm
                                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                        title={<IntlMessage id={'deleteCourse'}/>}
                                                        description={<IntlMessage id={'doYouWantDelete'}/>}
                                                        onConfirm={()=> {handleDeleteCourse(record.id)}}
                                                        className={classes.deleteBlock}
                                                        okText={<IntlMessage id={'yes'}/>}
                                                        cancelText={<IntlMessage id={'no'}/>}
                                                    >
                                                        <p className={classes.deleteBtn}><IntlMessage id={'delete'}/></p>
                                                    </Popconfirm>,
                                                    width: '15%',
                                                },
                                            ]}
                                            dataSource={category.courses}
                                            bordered
                                            rowKey={record => record.id}
                                            pagination={false}
                                        />
                                    </Flex>
                                }]}
                        />
                    )
                })
            }
        </Flex>
    );
};

export default CoursesList;