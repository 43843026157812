import React, {useEffect, useState} from 'react';
import {Flex, message} from "antd";

const HomePage = () => {
    return (
        <Flex style={{width: "100%"}} align={"center"} justify={"center"} wrap={"wrap"} gap={10}>

        </Flex>
    );
};

export default HomePage;