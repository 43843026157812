import React from 'react';
import classes from './StatsSection.module.css';
import StatItem from "./StatItem";
import {Bounce, Fade, Flip, Hinge, JackInTheBox, Roll, Zoom} from "react-awesome-reveal";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";

const Stats = () => {
    return (
        <div className={classes.stats}>
            <JackInTheBox triggerOnce={true} cascade damping={0.1}>
                <StatItem header={<IntlMessage id={'statsHeader1'}/>} body={<IntlMessage id={'statsBody1'}/>}/>
                <StatItem header={<IntlMessage id={'statsHeader2'}/>} body={<IntlMessage id={'statsBody2'}/>}/>
                <StatItem header={<IntlMessage id={'statsHeader3'}/>} body={<IntlMessage id={'statsBody3'}/>}/>
                <StatItem header={<IntlMessage id={'statsHeader4'}/>} body={<IntlMessage id={'statsBody4'}/>}/>
            </JackInTheBox>

        </div>
    );
};

export default Stats;