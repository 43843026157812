import React, {useEffect, useState} from 'react';
import {
    Checkbox,
    Col,
    Form,
    Input,
    message,
    Popconfirm,
    Row,
    Upload,
    Button as AntdButton,
    Flex,
    Image,
    Badge, Modal, Button, Alert, Steps
} from "antd";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import FullLoading from "../../components/LoadingComponents/FullLoading";
// import cl from "../../components/Header/Header.module.css";
import logo_black from "../../images/CCE_logo_black.svg";
import classes from './Form.module.css';
import BlockLoading from "../../components/LoadingComponents/BlockLoading";
import FormItem from "antd/es/form/FormItem";
import {getFormById, setForm} from "../../store/slices/FormSlice";
import {LocalName} from "../../utils/LocalName";
import {clrs} from "../../constants/colors";
import FormService from "../../services/FormService";
import {DeleteFilled, EyeOutlined, QuestionCircleOutlined, UploadOutlined} from "@ant-design/icons";
import {API_BASE_URL} from "../../constants/api";
import FileUploaderService from "../../services/FileUploaderService";
import IntlMessage, {IntlMessageText} from "../../components/IntlMessage/IntlMessage";
import {useTranslation} from "react-i18next";
import MyText from "../../components/UI/Text/MyText";
import GreyButton from "../../components/UI/Buttons/GreyButton";
import LogoBlack from "../components/Logo/LogoBlack";
import RedButton from "../../components/UI/Buttons/RedButton";
import GreenButton from "../../components/UI/Buttons/GreenButton";
import HorizontalDivider from "../../components/Divider/HorizontalDivider";
import { Document, Page, pdfjs  } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import {EmailValidator} from "../../utils/EmailValidator";
import InputMask from 'react-input-mask';
import {Helmet} from "react-helmet";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const FormPage = () => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const [form] = Form.useForm();
    const [formForDiscount] = Form.useForm();

    const {user, isLoading} = useSelector(state => state.user);

    const {formId} = useParams();

    const getForm = useSelector(state => state.form);

    const discount = Form.useWatch('discount', formForDiscount);

    const firstNameStudentInDoc = Form.useWatch('firstNameStudentInDoc', form);
    const middleNameStudentInDoc = Form.useWatch('middleNameStudentInDoc', form);
    const lastNameStudentInDoc = Form.useWatch('lastNameStudentInDoc', form);
    const firstNameStudentInEnglish = Form.useWatch('firstNameStudentInEnglish', form);
    const middleNameStudentInEnglish = Form.useWatch('middleNameStudentInEnglish', form);
    const lastNameStudentInEnglish = Form.useWatch('lastNameStudentInEnglish', form);
    const birthDay = Form.useWatch('birthDay', form);
    const address = Form.useWatch('address', form);
    const telNumber = Form.useWatch('telNumber', form);
    const email = Form.useWatch('email', form);
    const selectedCourses = Form.useWatch('courses', form);
    const iin = Form.useWatch('iin', form);
    const idCardNumber = Form.useWatch('idCardNumber', form);
    const idCardFromWhom = Form.useWatch('idCardFromWhom', form);
    const idCardDate = Form.useWatch('idCardDate', form);

    const [yes18, setYes18] = useState(false);
    const [isTransh, setIsTransh] = useState(false);

    const fullNameParentInDoc = Form.useWatch('fullNameParentInDoc', form);
    const telNumberParent = Form.useWatch('telNumberParent', form);
    const iin_parent = Form.useWatch('iin_parent', form);
    const idCardNumber_parent = Form.useWatch('idCardNumber_parent', form);
    const idCardFromWhom_parent = Form.useWatch('idCardFromWhom_parent', form);
    const idCardDate_parent = Form.useWatch('idCardDate_parent', form);

    const [formSaveLoading, setFormSaveLoading] = useState(false);
    const [isLoadingDiscount, setIsLoadingDiscount] = useState(false);

    const [idCardFiles, setIdCardFiles] = useState([]);

    const [preferentialFiles, setPreferentialFiles] = useState([
        {
            uid: '-1',
            name: 'xxx.png',
            url: 'http://www.baidu.com/xxx.png',
        },
    ]);

    const [otherFiles, setOtherFiles] = useState([
        {
            uid: '-1',
            name: 'xxx.png',
            url: 'http://www.baidu.com/xxx.png',
        },
    ]);

    async function handleCompleteForm() {
        message.loading(<IntlMessage id={'changing'}/>, 0);
        await FormService.completeFormById(formId)
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'changed'}/>, 5);
                dispatch(getFormById({formId}));
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'formPage.completeForm.error'}/>, 5);
                dispatch(getFormById({formId}));
            })
    }

    async function handleContractForm() {
        message.loading(<IntlMessage id={'changing'}/>, 0);
        await FormService.contractFormById(formId)
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);
                dispatch(getFormById({formId}));
                navigate('/p/contracts');
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
                dispatch(getFormById({formId}));
            })
    }

    const handleChangeIdCardFiles = async (info) => {
        setIsLoadingIdCardFiles(true);
        if (info.file?.status === 'removed') {
            await FormService.deleteFileFromForm(formId, info.file.uid)
                .then((formResult) => {
                    message.success(<IntlMessage id={'fileDeleted'}/>, 5);
                    setIdCardFiles(formResult.data.idCardFiles?.map(idCardFile => {
                        return {
                            uid: idCardFile.id,
                            name: idCardFile.label,
                            url: API_BASE_URL + "/api/file/get/" + idCardFile.fileName,
                        }
                    }))
                    dispatch(setForm(formResult.data))
                })
                .catch(() => {
                    message.error(<IntlMessage id={'error'}/>, 5);
                })
                .finally(() => {
                    setIsLoadingIdCardFiles(false);
                })
        } else {
            await FileUploaderService.uploadFile(info.file)
                .then(async (r) => {
                    await FormService.addIdCardFileToForm(formId, r.data.id)
                        .then((formResult) => {
                            message.success(<IntlMessage id={'fileSuccessfullyUploaded'}/>, 5);
                            setIdCardFiles(formResult.data.idCardFiles?.map(idCardFile => {
                                return {
                                    uid: idCardFile.id,
                                    name: idCardFile.label,
                                    url: API_BASE_URL + "/api/file/get/" + idCardFile.fileName,
                                }
                            }))
                            dispatch(setForm(formResult.data))
                        })
                        .catch(() => {
                            message.error(<IntlMessage id={'error'}/>, 5);
                        })
                        .finally(() => {
                            setIsLoadingIdCardFiles(false);
                        })
                })
                .catch((r) => {
                    setIsLoadingIdCardFiles(false);
                    message.error(<IntlMessage id={'error'}/>, 5);
                })
        }
    };
    const handleChangePreferentialFiles = async (info) => {
        setIsLoadingPreferentialFiles(true);
        if (info.file?.status === 'removed') {
            await FormService.deleteFileFromForm(formId, info.file.uid)
                .then((formResult) => {
                    message.success(<IntlMessage id={'fileDeleted'}/>, 5);
                    setPreferentialFiles(formResult.data.preferentialFiles?.map(preferentialFile => {
                        return {
                            uid: preferentialFile.id,
                            name: preferentialFile.label,
                            url: API_BASE_URL + "/api/file/get/" + preferentialFile.fileName,
                        }
                    }))
                    dispatch(setForm(formResult.data))
                })
                .catch(() => {
                    message.error(<IntlMessage id={'error'}/>, 5);
                })
                .finally(() => {
                    setIsLoadingPreferentialFiles(false);
                })
        } else {
            await FileUploaderService.uploadFile(info.file)
                .then(async (r) => {
                    await FormService.addPreferentialFileToForm(formId, r.data.id)
                        .then((formResult) => {
                            message.success(<IntlMessage id={'fileSuccessfullyUploaded'}/>, 5);
                            setPreferentialFiles(formResult.data.preferentialFiles?.map(preferentialFile => {
                                return {
                                    uid: preferentialFile.id,
                                    name: preferentialFile.label,
                                    url: API_BASE_URL + "/api/file/get/" + preferentialFile.fileName,
                                }
                            }))
                            dispatch(setForm(formResult.data))
                        })
                        .catch(() => {
                            message.error(<IntlMessage id={'error'}/>, 5);
                        })
                        .finally(() => {
                            setIsLoadingPreferentialFiles(false);
                        })
                })
                .catch((r) => {
                    setIsLoadingPreferentialFiles(false);
                    message.error(<IntlMessage id={'error'}/>, 5);
                })
        }
    };
    const handleChangeOtherFiles = async (info) => {
        setIsLoadingOtherFiles(true);
        if (info.file?.status === 'removed') {
            await FormService.deleteFileFromForm(formId, info.file.uid)
                .then((formResult) => {
                    message.success(<IntlMessage id={'fileDeleted'}/>, 5);
                    setOtherFiles(formResult.data.otherFiles?.map(otherFile => {
                        return {
                            uid: otherFile.id,
                            name: otherFile.label,
                            url: API_BASE_URL + "/api/file/get/" + otherFile.fileName,
                        }
                    }))
                    dispatch(setForm(formResult.data))
                })
                .catch(() => {
                    message.error(<IntlMessage id={'error'}/>, 5);
                })
                .finally(() => {
                    setIsLoadingOtherFiles(false);
                })
        } else {
            await FileUploaderService.uploadFile(info.file)
                .then(async (r) => {
                    await FormService.addOtherFileToForm(formId, r.data.id)
                        .then((formResult) => {
                            message.success(<IntlMessage id={'fileSuccessfullyUploaded'}/>, 5);
                            setOtherFiles(formResult.data.otherFiles?.map(otherFile => {
                                return {
                                    uid: otherFile.id,
                                    name: otherFile.label,
                                    url: API_BASE_URL + "/api/file/get/" + otherFile.fileName,
                                }
                            }))
                            dispatch(setForm(formResult.data))
                        })
                        .catch(() => {
                            message.error(<IntlMessage id={'error'}/>, 5);
                        })
                        .finally(() => {
                            setIsLoadingOtherFiles(false);
                        })
                })
                .catch((r) => {
                    setIsLoadingOtherFiles(false);
                    message.error(<IntlMessage id={'error'}/>, 5);
                })
        }
    };

    const propsIdCardFiles = {
        beforeUpload: (info) => {return false},
        onChange: handleChangeIdCardFiles,
        multiple: false,
    };
    const propsPreferentialFiles = {
        beforeUpload: (info) => {return false},
        onChange: handleChangePreferentialFiles,
        multiple: false,
    };
    const propsOtherFiles = {
        beforeUpload: (info) => {return false},
        onChange: handleChangeOtherFiles,
        multiple: false,
    };
    const [isLoadingIdCardFiles, setIsLoadingIdCardFiles] = useState(false);
    const [isLoadingPreferentialFiles, setIsLoadingPreferentialFiles] = useState(false);
    const [isLoadingOtherFiles, setIsLoadingOtherFiles] = useState(false);

    function handleSaveForm() {
        form.validateFields()
            .then(async () => {
                setFormSaveLoading(true);
                const formSave = {
                    courseIds: selectedCourses,
                    isTransh: isTransh,
                    firstName: firstNameStudentInDoc,
                    middleName: middleNameStudentInDoc,
                    lastName: lastNameStudentInDoc,
                    firstNameInEnglish: firstNameStudentInEnglish,
                    middleNameInEnglish: middleNameStudentInEnglish,
                    lastNameInEnglish: lastNameStudentInEnglish,
                    email: email,
                    telNumber: telNumber.endsWith('_') ? telNumber.slice(0, -1) : telNumber,
                    address: address,
                    iin: iin.endsWith('_') ? iin.slice(0, -1) : iin,
                    idCardNumber: idCardNumber,
                    idCardDate: idCardDate,
                    idCardFromWhom: idCardFromWhom,
                    birthDay: birthDay,
                    yes18: !yes18,
                    parentFullName: fullNameParentInDoc,
                    parentTelNumber: telNumberParent,
                    parentIin: iin_parent,
                    parentIdCardNumber: idCardNumber_parent,
                    parentIdCardDate: idCardDate_parent,
                    parentIdCardFromWhom: idCardFromWhom_parent,
                }
                await FormService.updateFormById(formId, formSave)
                    .then(() => {
                        message.success(<IntlMessage id={'formSaved'}/>, 5);
                    })
                    .catch(() => {
                        message.error(<IntlMessage id={'error'}/>, 5);
                    })
                    .finally(() => {
                        setFormSaveLoading(false);
                    })
            })
            .catch(() => {

            })

    }

    function handleUpdateDiscountForm() {
        form.validateFields()
            .then(async () => {
                setIsLoadingDiscount(true);

                await FormService.updateDiscountOfFormById(formId, discount)
                    .then(() => {
                        message.success(<IntlMessage id={'formSaved'}/>, 5);
                    })
                    .catch(() => {
                        message.error(<IntlMessage id={'error'}/>, 5);
                    })
                    .finally(() => {
                        setIsLoadingDiscount(false);
                    })
            })
            .catch(() => {

            })
    }

    const handleInputChange = (e, fieldName) => {
        const inputValue = e.target.value;
        const formattedInput = inputValue.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
        form.setFieldsValue(
            { [fieldName]: formattedInput }
        );
    };

    useEffect(() => {
        dispatch(getFormById({formId}));
    }, []);

    useEffect(() => {
        if (getForm.form?.userData !== null && getForm.form?.userData !== undefined) {
            setYes18(!getForm.form.userData?.yes18);
        }
        if (getForm.form !== null && getForm.form !== undefined) {
            setIsTransh(getForm.form.isTransh);
            setIdCardFiles(getForm.form.idCardFiles?.map(idCardFile => {
                return {
                    uid: idCardFile.id,
                    name: idCardFile.label,
                    url: API_BASE_URL + "/api/file/get/" + idCardFile.fileName,
                }
            }))
            setPreferentialFiles(getForm.form.preferentialFiles?.map(preferentialFile => {
                return {
                    uid: preferentialFile.id,
                    name: preferentialFile.label,
                    url: API_BASE_URL + "/api/file/get/" + preferentialFile.fileName,
                }
            }))
            setOtherFiles(getForm.form.otherFiles?.map(otherFile => {
                return {
                    uid: otherFile.id,
                    name: otherFile.label,
                    url: API_BASE_URL + "/api/file/get/" + otherFile.fileName,
                }
            }))
        }

    }, [getForm.form]);


    if (getForm.isLoading) {
        return <FullLoading/>
    }

    if (getForm.form === null) {
        return (
            <Flex style={{width: "100%", position: "relative", padding: "120px 20px 20px 20px"}} align={"center"} justify={"center"}>
                <Flex gap={50} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                      align={"center"} justify={"center"}>
                    <LogoBlack/>
                    <MyText size={"large"} uppercase strong><IntlMessage id={'formNotFound'}/></MyText>
                </Flex>
            </Flex>
        )
    }

    return (
        <Flex style={{width: "100%", position: "relative", padding: "120px 20px 20px 20px"}} align={"center"} justify={"center"}>
            {
                getForm.form?.userData !== null && getForm.form?.userData !== undefined && getForm.form?.userData?.fullName !== null &&
                <Helmet>
                    <title>{getForm.form?.userData?.fullName}</title>
                </Helmet>
            }
            <Flex gap={50} style={{width: "100%", maxWidth: 1440, position: "relative"}} vertical
                  align={"center"} justify={"center"}>
                <LogoBlack/>
                <MyText size={"large"} uppercase strong><IntlMessage id={'form'}/></MyText>
                <Flex className={classes.form} vertical>
                    <Alert
                        style={{
                            width: "100%"
                        }}
                        type={"info"}
                        showIcon
                        message={<strong><IntlMessage id={'formPage.instruction'}/>:</strong>}
                        description={
                            <Flex>
                                <Steps
                                    size={"small"}
                                    progressDot
                                    current={100}
                                    direction={"vertical"}
                                    items={[
                                        {
                                            title: <IntlMessage id={'formPage.instruction.step1_header'}/>,
                                            description: <IntlMessage id={'formPage.instruction.step1_desc'}/>,
                                        },
                                        {
                                            title: <IntlMessage id={'formPage.instruction.step2_header'}/>,
                                            description: <IntlMessage id={'formPage.instruction.step2_desc'}/>,
                                        },
                                        {
                                            title: <IntlMessage id={'formPage.instruction.step3_header'}/>,
                                            description: <IntlMessage id={'formPage.instruction.step3_desc'}/>,
                                        },
                                        {
                                            title: <IntlMessage id={'formPage.instruction.step4_header'}/>,
                                            description: <IntlMessage id={'formPage.instruction.step4_desc'}/>,
                                        },
                                        {
                                            title: <IntlMessage id={'formPage.instruction.step5_header'}/>,
                                            description: <IntlMessage id={'formPage.instruction.step5_desc'}/>,
                                        },
                                        {
                                            title: <IntlMessage id={'formPage.instruction.step6_header'}/>,
                                            description: <IntlMessage id={'formPage.instruction.step6_desc'}/>,
                                        },
                                        {
                                            title: <IntlMessage id={'formPage.instruction.step7_header'}/>,
                                            description: <IntlMessage id={'formPage.instruction.step7_desc'}/>,
                                        }
                                    ]}
                                />
                            </Flex>
                        }
                    />
                    <Alert
                        style={{
                            width: "100%"
                        }}
                        type={"info"}
                        showIcon
                        message={<strong><IntlMessage id={'formPage.instruction.warning'}/></strong>}
                        description={
                            <Flex vertical gap={10}>
                                <p>- <IntlMessage id={'formPage.instruction.warning.1'}/> <Link style={{textDecoration: "underline"}} target={"_blank"} to={'https://cce.astanait.edu.kz/api/file/get/1f2201af-5c79-47c6-9f49-6609761b64a0.pdf'}><IntlMessage id={'formPage.instruction.warning.1.1'}/></Link></p>
                                <p>- <IntlMessage id={'formPage.instruction.warning.2'}/></p>
                                <p>- <IntlMessage id={'formPage.instruction.warning.3'}/></p>
                                <p>- <IntlMessage id={'formPage.instruction.warning.4'}/></p>
                            </Flex>
                        }
                    />
                </Flex>
                <Form form={form} className={classes.form}
                      initialValues={{
                          firstNameStudentInDoc: getForm.form?.userData?.firstName,
                          middleNameStudentInDoc: getForm.form?.userData?.middleName,
                          lastNameStudentInDoc: getForm.form?.userData?.lastName,
                          firstNameStudentInEnglish: getForm.form?.userData?.firstNameInEnglish,
                          middleNameStudentInEnglish: getForm.form?.userData?.middleNameInEnglish,
                          lastNameStudentInEnglish: getForm.form?.userData?.lastNameInEnglish,
                          birthDay: getForm.form?.userData?.birthDay,
                          address: getForm.form?.userData?.address,
                          telNumber: getForm.form?.userData?.telNumber,
                          email: getForm.form?.userData?.email,
                          courses: getForm.form?.courses.map(course => {return course.id}),
                          iin: getForm.form?.userData?.iin,
                          idCardNumber: getForm.form?.userData?.idCardNumber,
                          idCardFromWhom: getForm.form?.userData?.idCardFromWhom || getForm.form?.userData?.idCardFromWhom == '' ? getForm.form?.userData?.idCardFromWhom : "МВД РК",
                          idCardDate: getForm.form?.userData?.idCardDate,
                          fullNameParentInDoc: getForm.form?.userData?.parentFullName,
                          telNumberParent: getForm.form?.userData?.parentTelNumber,
                          iin_parent: getForm.form?.userData?.parentIin,
                          idCardNumber_parent: getForm.form?.userData?.parentIdCardNumber,
                          idCardFromWhom_parent: getForm.form?.userData?.parentIdCardFromWhom || getForm.form?.userData?.parentIdCardFromWhom == '' ? getForm.form?.userData?.parentIdCardFromWhom : "МВД РК",
                          idCardDate_parent: getForm.form?.userData?.parentIdCardDate,
                      }}
                >
                    <BlockLoading isLoading={formSaveLoading}/>
                    <FormItem rules={[{
                        required: true,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                    ]} label={<IntlMessage id={'firstNameStudentInDoc'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="firstNameStudentInDoc">
                        <Input onChange={(e) => {handleInputChange(e, "firstNameStudentInDoc")}} disabled={getForm.form?.applicationStatus === 'COMPLETED'} rootClassName={classes.formInput}  />
                    </FormItem>
                    <FormItem rules={[{
                        required: true,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                    ]} label={<IntlMessage id={'lastNameStudentInDoc'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="lastNameStudentInDoc">
                        <Input onChange={(e) => {handleInputChange(e, "lastNameStudentInDoc")}} disabled={getForm.form?.applicationStatus === 'COMPLETED'} rootClassName={classes.formInput}  />
                    </FormItem>
                    <FormItem rules={[{
                        required: false,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                    ]} label={<IntlMessage id={'middleNameStudentInDoc'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="middleNameStudentInDoc">
                        <Input onChange={(e) => {handleInputChange(e, "middleNameStudentInDoc")}} disabled={getForm.form?.applicationStatus === 'COMPLETED'} rootClassName={classes.formInput}  />
                    </FormItem>
                    <FormItem rules={[{
                        required: true,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                    ]} label={<IntlMessage id={'firstNameStudentInEnglish'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="firstNameStudentInEnglish">
                        <Input onChange={(e) => {handleInputChange(e, "firstNameStudentInEnglish")}} disabled={getForm.form?.applicationStatus === 'COMPLETED'} rootClassName={classes.formInput}  />
                    </FormItem>
                    <FormItem rules={[{
                        required: true,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                    ]} label={<IntlMessage id={'lastNameStudentInEnglish'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="lastNameStudentInEnglish">
                        <Input onChange={(e) => {handleInputChange(e, "lastNameStudentInEnglish")}} disabled={getForm.form?.applicationStatus === 'COMPLETED'} rootClassName={classes.formInput}  />
                    </FormItem>
                    <FormItem rules={[{
                        required: false,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                    ]} label={<IntlMessage id={'middleNameStudentInEnglish'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="middleNameStudentInEnglish">
                        <Input onChange={(e) => {handleInputChange(e, "middleNameStudentInEnglish")}} disabled={getForm.form?.applicationStatus === 'COMPLETED'} rootClassName={classes.formInput}  />
                    </FormItem>
                    <FormItem rules={[{
                        required: true,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                    ]} label={<IntlMessage id={'birthDay'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="birthDay">
                        <Input disabled={getForm.form?.applicationStatus === 'COMPLETED'} type={'date'} rootClassName={classes.formInput}  />
                    </FormItem>
                    <FormItem rules={[{
                        required: true,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                    ]} label={<IntlMessage id={'address'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="address">
                        <Input disabled={getForm.form?.applicationStatus === 'COMPLETED'} rootClassName={classes.formInput}  />
                    </FormItem>
                    <FormItem rules={[{
                        required: true,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                    ]} label={<IntlMessage id={'telNumber'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="telNumber">
                        <InputMask
                            mask="+9 (999) 999-9999"
                            disabled={getForm.form?.applicationStatus === 'COMPLETED'}
                            className={classes.formInput}
                        >
                            {(inputProps) => <Input disabled={getForm.form?.applicationStatus === 'COMPLETED'} {...inputProps} />}
                        </InputMask>
                        {/*<Input disabled={getForm.form?.applicationStatus === 'COMPLETED'} rootClassName={classes.formInput}  />*/}
                    </FormItem>
                    <FormItem rules={[{
                        required: true,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (EmailValidator.isValid(value)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(t('emailRules')));
                            },
                        }),
                    ]} label={<IntlMessage id={'email'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="email">
                        <Input disabled={getForm.form?.applicationStatus === 'COMPLETED'} rootClassName={classes.formInput}  />
                    </FormItem>

                    <FormItem rules={[{
                        required: true,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                    ]} label={t('courses') + " " + `(${LocalName.getName(getForm.form.category)})`} labelCol={{ span: 24 }} className={classes.formInputBlock} name="courses">
                        <Checkbox.Group
                            style={{
                                width: '100%',
                            }}
                            disabled={getForm.form?.applicationStatus === 'COMPLETED'}
                        >
                            <Flex align={"flex-start"} justify={"flex-start"} wrap={"wrap"} gap={20} style={{width: "100%"}}>
                                {
                                    getForm.form?.category.courses.map((course, i) => {
                                        return (
                                            <Checkbox key={course.id} value={course.id}>{LocalName.getName(course)}</Checkbox>
                                        )
                                    })
                                }

                            </Flex>
                        </Checkbox.Group>
                    </FormItem>
                    {
                        (getForm.form?.category?.nameEng !== 'General English' && getForm.form?.category?.nameEng !== 'Mathematics') &&
                        <Checkbox disabled={getForm.form?.applicationStatus === 'COMPLETED'} onChange={() => {setIsTransh(!isTransh)}} defaultChecked={getForm.form?.isTransh} >
                            <IntlMessage id={'isTransh'}/>
                        </Checkbox>
                    }

                    <FormItem rules={[{
                        required: true,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                    ]} label={<IntlMessage id={'iin'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="iin">
                        <InputMask
                            mask="999999999999"
                            disabled={getForm.form?.applicationStatus === 'COMPLETED'}
                            className={classes.formInput}
                        >
                            {(inputProps) => <Input disabled={getForm.form?.applicationStatus === 'COMPLETED'} {...inputProps} />}
                        </InputMask>
                    </FormItem>
                    <FormItem rules={[{
                        required: true,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                    ]} label={<IntlMessage id={'idCardNumber'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="idCardNumber">
                        <Input disabled={getForm.form?.applicationStatus === 'COMPLETED'} rootClassName={classes.formInput} />
                    </FormItem>
                    <FormItem rules={[{
                        required: true,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                    ]} label={<IntlMessage id={'idCardFromWhom'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="idCardFromWhom">
                        <Input disabled={getForm.form?.applicationStatus === 'COMPLETED'} rootClassName={classes.formInput}  />
                    </FormItem>
                    <FormItem rules={[{
                        required: true,
                        message: <IntlMessage id={'requiredField'}/>,
                    },
                    ]} label={<IntlMessage id={'idCardDate'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="idCardDate">
                        <Input disabled={getForm.form?.applicationStatus === 'COMPLETED'} type={'date'} rootClassName={classes.formInput}  />
                    </FormItem>
                    <Checkbox disabled={getForm.form?.applicationStatus === 'COMPLETED'} onChange={() => {setYes18(!yes18)}} defaultChecked={getForm.form.userData === null ? false : !getForm.form?.userData?.yes18} >
                        <IntlMessage id={'IamNotAdult'}/>
                    </Checkbox>
                    {
                        yes18 &&
                        <>
                            <FormItem rules={[{
                                required: true,
                                message: <IntlMessage id={'requiredField'}/>,
                            },
                            ]} label={<IntlMessage id={'fullNameParentInDoc'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="fullNameParentInDoc">
                                <Input onChange={(e) => {handleInputChange(e, "fullNameParentInDoc")}} disabled={getForm.form?.applicationStatus === 'COMPLETED'} rootClassName={classes.formInput}  />
                            </FormItem>
                            <FormItem rules={[{
                                required: true,
                                message: <IntlMessage id={'requiredField'}/>,
                            },
                            ]} label={<IntlMessage id={'telNumberParent'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="telNumberParent">
                                <Input disabled={getForm.form?.applicationStatus === 'COMPLETED'} rootClassName={classes.formInput}  />
                            </FormItem>
                            <FormItem rules={[{
                                required: true,
                                message: <IntlMessage id={'requiredField'}/>,
                            },
                            ]} label={<IntlMessage id={'iin_parent'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="iin_parent">
                                <Input disabled={getForm.form?.applicationStatus === 'COMPLETED'} rootClassName={classes.formInput} />
                            </FormItem>
                            <FormItem rules={[{
                                required: true,
                                message: <IntlMessage id={'requiredField'}/>,
                            },
                            ]} label={<IntlMessage id={'idCardNumber_parent'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="idCardNumber_parent">
                                <Input disabled={getForm.form?.applicationStatus === 'COMPLETED'} rootClassName={classes.formInput}  />
                            </FormItem>
                            <FormItem rules={[{
                                required: true,
                                message: <IntlMessage id={'requiredField'}/>,
                            },
                            ]} label={<IntlMessage id={'idCardFromWhom_parent'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="idCardFromWhom_parent">
                                <Input disabled={getForm.form?.applicationStatus === 'COMPLETED'} rootClassName={classes.formInput}  />
                            </FormItem>
                            <FormItem rules={[{
                                required: true,
                                message: <IntlMessage id={'requiredField'}/>,
                            },
                            ]} label={<IntlMessage id={'idCardDate_parent'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="idCardDate_parent">
                                <Input disabled={getForm.form?.applicationStatus === 'COMPLETED'} type={'date'} rootClassName={classes.formInput} />
                            </FormItem>
                        </>
                    }
                    {
                        getForm.form?.applicationStatus !== 'COMPLETED' &&
                        <FormItem className={classes.formInputBlock}>
                            <Flex align={"flex-start"} justify={"center"} gap={20} style={{width: "100%"}}>
                                <GreyButton onClick={handleSaveForm}>
                                    <IntlMessage id={'save'}/>
                                </GreyButton>
                            </Flex>
                        </FormItem>
                    }
                </Form>
                <Form className={classes.form}>
                    <BlockLoading isLoading={isLoadingIdCardFiles}/>
                    <FormItem label={<IntlMessage id={'personalDocuments'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock}>
                        <Flex align={"flex-start"} vertical justify={"flex-start"} gap={10} style={{width: "100%", position: "relative"}}>
                            <Upload {...propsIdCardFiles} fileList={[]}>
                                <AntdButton icon={<UploadOutlined />}><IntlMessage id={'upload'}/></AntdButton>
                            </Upload>
                            <HorizontalDivider/>
                            <FileList files={getForm.form?.idCardFiles} setIsLoading={setIsLoadingIdCardFiles}/>
                        </Flex>
                    </FormItem>
                </Form>
                {
                    (getForm.form?.category?.nameEng !== 'General English' && getForm.form?.category?.nameEng !== 'Mathematics') &&
                    <Form className={classes.form}>
                        <BlockLoading isLoading={isLoadingPreferentialFiles}/>
                        <FormItem label={<IntlMessage id={'preferentialDocuments'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock}>
                            <Flex align={"flex-start"} vertical justify={"flex-start"} gap={10} style={{width: "100%"}}>
                                <Upload {...propsPreferentialFiles} fileList={[]}>
                                    <AntdButton icon={<UploadOutlined />}><IntlMessage id={'upload'}/></AntdButton>
                                </Upload>
                                <HorizontalDivider/>
                                <FileList files={getForm.form?.preferentialFiles} setIsLoading={setIsLoadingPreferentialFiles}/>
                            </Flex>
                        </FormItem>
                    </Form>
                }

                <Form className={classes.form}>
                    <BlockLoading isLoading={isLoadingOtherFiles}/>
                    <FormItem label={<IntlMessage id={'otherDocuments'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock}>
                        <Flex align={"flex-start"} vertical justify={"flex-start"} gap={10} style={{width: "100%"}}>
                            <Upload {...propsOtherFiles} fileList={[]}>
                                <AntdButton icon={<UploadOutlined />}><IntlMessage id={'upload'}/></AntdButton>
                            </Upload>
                            <HorizontalDivider/>
                            <FileList files={getForm.form?.otherFiles} setIsLoading={setIsLoadingOtherFiles}/>
                        </Flex>
                    </FormItem>
                </Form>
                {
                    (getForm.form?.category?.nameEng !== 'General English' && getForm.form?.category?.nameEng !== 'Mathematics') && (user !== null && user?.role.roleName === 'admin') &&
                    <Form form={formForDiscount} className={classes.form}
                          initialValues={{
                              discount: getForm.form?.discount,
                          }}>
                        <BlockLoading isLoading={isLoadingDiscount}/>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                        ]} label={<IntlMessage id={'discount'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="discount">
                            <Input min={0} type={'number'} disabled={getForm.form?.applicationStatus === 'COMPLETED'} rootClassName={classes.formInput}  />
                        </FormItem>
                        {
                            getForm.form?.applicationStatus !== 'COMPLETED' &&
                            <FormItem className={classes.formInputBlock}>
                                <Flex align={"center"} justify={"center"} gap={20} style={{width: "100%"}}>
                                    <GreyButton onClick={handleUpdateDiscountForm}>
                                        <IntlMessage id={'save'}/>
                                    </GreyButton>
                                </Flex>
                            </FormItem>
                        }
                    </Form>
                }

                {
                    getForm.form?.applicationStatus === 'COMPLETED' &&
                    <Form className={classes.form}>
                        <FormItem className={classes.formInputBlock}>
                            <Flex align={"flex-start"} justify={"center"} gap={20} style={{width: "100%"}}>
                                <GreyButton onClick={() => {window.open(API_BASE_URL + "/api/generator/contract?formId=" + formId, '_blank')}} >
                                    <IntlMessage id={'downloadContract'}/>
                                </GreyButton>
                            </Flex>
                        </FormItem>
                    </Form>
                }

                {
                    getForm.form?.applicationStatus !== 'COMPLETED' &&
                    <div className={classes.completeBlock}>
                        <Flex align={"flex-start"} justify={"center"} gap={20} style={{width: "100%"}}>
                            <Popconfirm
                                // icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                title={t('statusToComplete')}
                                description={t('youSureToCompleteForm')}
                                onConfirm={()=> {handleCompleteForm()}}
                                // className={classes.deleteBlock}
                                okText={t('yes')}
                                cancelText={t('no')}
                            >
                                <GreenButton>
                                    <IntlMessage id={'statusToComplete'}/>
                                </GreenButton>
                            </Popconfirm>
                        </Flex>
                    </div>
                }

                {
                    (user !== null && user?.role.roleName === 'admin') && getForm.form?.applicationStatus === 'COMPLETED' &&
                    <div className={classes.completeBlock}>
                        <Flex align={"center"} justify={"center"} gap={20} style={{width: "100%"}}>
                            <Popconfirm
                                // icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                title={t('statusToContract')}
                                description={t('youSureToContractForm')}
                                onConfirm={()=> {handleContractForm()}}
                                // className={classes.deleteBlock}
                                okText={t('yes')}
                                cancelText={t('no')}
                            >
                                <RedButton >
                                    <IntlMessage id={'statusToContract'}/>
                                </RedButton>
                            </Popconfirm>
                        </Flex>
                    </div>
                }
            </Flex>


        </Flex>
    );
};

const FileList = ({files, setIsLoading}) => {
    const imgExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.svg', '.webp', '.heic', '.heif'];
    const pdfExtension = '.pdf';

    const {formId} = useParams();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        setPageNumber(1);
    }, [open]);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    async function handleDeletion(file) {
        setIsLoading(true);
        await FormService.deleteFileFromForm(formId, file.id)
            .then((formResult) => {
                message.success(<IntlMessage id={'fileDeleted'}/>, 5);
                dispatch(setForm(formResult.data))
            })
            .catch(() => {
                message.error(<IntlMessage id={'error'}/>, 5);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }
    if (!files || files?.length === 0) {
        return '';
    }
    return (
        <Flex align={"flex-start"} vertical justify={"flex-start"} gap={10} style={{width: "100%", position: "relative"}}>

            <Modal
                maskClosable={true}
                width={800}
                open={open}
                style={{
                    top: 20,
                }}
                onOk={() => {setOpen(false)}}
                onCancel={() => {setOpen(false)}}
                footer={[
                    <Button key="submit" type="primary" onClick={() => {setOpen(false)}}>
                        OK
                    </Button>
                ]}
            >
                <Flex align={"center"} justify={"center"} style={{overflowX: "auto", overflowY: "hidden", width: "100%", position: "relative"}}>
                    <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} width={700}/>
                    </Document>
                    <Flex style={{
                        position: "absolute",
                        left: "50%",
                        transform: "translateX(-50%)",
                        top: 10,
                        zIndex: 100,
                        padding: "10px 20px",
                        backgroundColor: "white",
                        borderRadius: 10,
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                    }} gap={15} justify={"space-between"} align={"center"}>
                        <Button disabled={pageNumber === 1} onClick={() => {setPageNumber(pageNumber - 1)}}>
                            {'<'}
                        </Button>
                        <MyText size={"small"}>
                            {pageNumber} / {numPages}
                        </MyText>
                        <Button disabled={pageNumber === numPages} onClick={() => {setPageNumber(pageNumber + 1)}}>
                            {'>'}
                        </Button>
                    </Flex>
                </Flex>

            </Modal>

            <Flex align={"flex-start"} justify={"flex-start"} wrap={"wrap"} gap={15} style={{width: "100%", position: "relative"}}>
                {
                    files?.map(file => {
                        if (imgExtensions.includes(file.extension)) {
                            return (
                                <Flex style={{width: 100, height: 100, position: "relative"}}>
                                    <Popconfirm
                                        title={<IntlMessage id={'delete'}/>}
                                        description={<IntlMessage id={'formPage.youSureToDelete'}/>}
                                        onConfirm={() => {handleDeletion(file)}}
                                        // onCancel={cancel}
                                        okText={<IntlMessage id={'yes'}/>}
                                        cancelText={<IntlMessage id={'no'}/>}
                                    >
                                        <Flex className={classes.fileItemDeleteBadge}
                                              style={{width: 20, height: 20, borderRadius: 100, top: -10, right: -10, position: "absolute", zIndex: 100}}
                                              align={"center"} justify={"center"}>
                                            <DeleteFilled style={{
                                                color: 'white',
                                                fontSize: '0.7rem'
                                            }}/>
                                        </Flex>
                                    </Popconfirm>
                                    <Image wrapperStyle={{maxHeight: 100, overflow: "hidden"}} width={100} src={API_BASE_URL + "/api/file/get/" + file.fileName}/>
                                </Flex>

                            )
                        }
                    })
                }
            </Flex>

            <HorizontalDivider/>
            <Flex align={"flex-start"} justify={"flex-start"} vertical gap={5} style={{width: "100%", position: "relative"}}>
                {
                    files?.map(file => {
                        if (!imgExtensions.includes(file.extension)) {
                            return (
                                <Flex align={"center"} justify={"flex-start"} gap={15}>
                                    {
                                        file.extension === pdfExtension &&
                                        <Flex onClick={() => {
                                            setPdfUrl(API_BASE_URL + "/api/file/get/" + file.fileName);
                                            setOpen(true);
                                        }} align={"center"} justify={"center"} className={classes.eyeFileItem}>
                                            <EyeOutlined />
                                        </Flex>
                                    }

                                    <Link target={"_blank"} to={API_BASE_URL + "/api/file/get/" + file.fileName}>
                                        <MyText key={file.id} className={classes.fileItem} size={"small"}>
                                            {file.label}
                                        </MyText>
                                    </Link>
                                    <Popconfirm
                                        title={<IntlMessage id={'delete'}/>}
                                        description={<IntlMessage id={'formPage.youSureToDelete'}/>}
                                        onConfirm={() => {handleDeletion(file)}}
                                        // onCancel={cancel}
                                        okText={<IntlMessage id={'yes'}/>}
                                        cancelText={<IntlMessage id={'no'}/>}
                                    >
                                        <Flex className={classes.fileItemDeleteBadge}
                                              style={{width: 20, height: 20, borderRadius: 100, zIndex: 100}}
                                              align={"center"} justify={"center"}>
                                            <DeleteFilled style={{
                                                color: 'white',
                                                fontSize: '0.7rem'
                                            }}/>
                                        </Flex>
                                    </Popconfirm>
                                </Flex>
                            )
                        }
                    })
                }
            </Flex>

        </Flex>
    )
}


export default FormPage;