import React, {useEffect, useState} from 'react';
import classes from './template.module.css';
import aituLogo from './images/white_logo_aitu.png';
import {useWindowSize} from "@uidotdev/usehooks";
import {Flex} from "antd";

const Uslovniki2023 = ({certificate}) => {
    const [name, setName] = useState(null);
    const [code, setCode] = useState(null);
    const [hours, setHours] = useState(null);
    const [discipline, setDiscipline] = useState(null);
    const [credits, setCredits] = useState(null);
    const [grade, setGrade] = useState(null);

    const size = useWindowSize();

    useEffect(() => {
        setName(certificate?.oldCertCharacs.find(charac => charac.label === '{full_name}')?.value)
        setCode(certificate?.code)
        setHours(certificate?.oldCertCharacs.find(charac => charac.label === '{hours}')?.value);
        setDiscipline(certificate?.oldCertCharacs.find(charac => charac.label === '{discipline}')?.value);
        setCredits(certificate?.oldCertCharacs.find(charac => charac.label === '{credits}')?.value);
        setGrade(certificate?.oldCertCharacs.find(charac => charac.label === '{grade}')?.value);
    }, [certificate]);

    if (name === null) {
        return null;
    }
    if (code === null) {
        return null;
    }
    if (hours === null) {
        return null;
    }
    if (discipline === null) {
        return null;
    }
    if (credits === null) {
        return null;
    }
    if (grade === null) {
        return null;
    }
    return (
        <Flex style={{width: "100%", position: "relative", padding: "120px 20px 20px 20px", overflowX: "auto"}}
              align={"center"} justify={size.width <= 1200 ? "flex-start" : "center"}>
            <Flex className={classes.blockTemplate} gap={50}
                  style={{width: "100%", maxWidth: 1440, position: "relative"}}
                  vertical align={"center"}
                  justify={"center"}>
                <div className={classes.gradient}></div>
                <div className={classes.uslovniki2032_aituLogoWhite}>
                    <img src={aituLogo} alt=""/>
                </div>
                <div className={classes.uslovniki2032_astana}>
                    <p>Астана, 2023</p>
                    <p>№ {code}</p>
                </div>
                <div className={classes.uslovniki2032_main}>
                    <p style={{fontSize: "3rem", fontWeight: "800"}}>СЕРТИФИКАТ</p>
                    <Flex vertical gap={20} align={"center"} justify={"center"} style={{width: "100%"}}>
                        <p style={{fontSize: "1.5rem", fontWeight: "700"}}>Настоящий сертификат подтверждает, что</p>
                        <p className={classes.uslovniki2032_name}
                           style={{fontSize: "2.2rem", fontWeight: "700"}}>{name}</p>
                        <p style={{fontSize: "1.5rem", textAlign: "center"}}>прослушал (а) <span
                            style={{fontWeight: "700"}}>{discipline}</span> в количестве <span
                            style={{fontWeight: "700"}}>{hours}</span> ч. с оценкой <span
                            style={{fontWeight: "700"}}>{grade}</span>.</p>

                    </Flex>
                    <Flex gap={20} justify={"space-between"} align={"center"} style={{width: "100%"}}>
                        <div>
                            <p style={{fontSize: "1.2rem", fontWeight: "700"}}></p>
                            <p style={{fontSize: "1.2rem", fontWeight: "700"}}></p>
                        </div>
                        <p style={{fontSize: "1.2rem", fontWeight: "700"}}></p>
                    </Flex>
                </div>
            </Flex>
        </Flex>
    );
};

export default Uslovniki2023;