import React, {useEffect, useState} from 'react';
import classes from './template.module.css';
import aituLogo from './images/white_logo_aitu.png';
import {useWindowSize} from "@uidotdev/usehooks";
import {Flex} from "antd";

const DekanatCert2023 = ({certificate}) => {
    const [name, setName] = useState(null);
    const [code, setCode] = useState(null);
    const [hours, setHours] = useState(null);
    const [course, setCourse] = useState(null);

    const size = useWindowSize();

    useEffect(() => {
        setName(certificate?.oldCertCharacs.find(charac => charac.label === '{full_name}')?.value)
        setCode(certificate?.code)
        setHours(certificate?.oldCertCharacs.find(charac => charac.label === '{hours}')?.value);
        setCourse(certificate?.oldCertCharacs.find(charac => charac.label === '{course}')?.value);
    }, [certificate]);

    if (name === null) {
        return null;
    }
    if (code === null) {
        return null;
    }
    if (hours === null) {
        return null;
    }
    if (course === null) {
        return null;
    }
    return (
        <Flex style={{width: "100%", position: "relative", padding: "120px 20px 20px 20px", overflowX: "auto"}}
              align={"center"}
              justify={size.width <= 1200 ? "flex-start" : "center"}>
            <Flex className={classes.blockTemplate} gap={50}
                  style={{width: "100%", maxWidth: 1440, position: "relative"}}
                  vertical align={"center"}
                  justify={"center"}>
                <div className={classes.gradient}></div>
                <div className={classes.uslovniki2032_aituLogoWhite}>
                    <img src={aituLogo} alt=""/>
                </div>
                <div className={classes.uslovniki2032_astana}>
                    <p>Астана, 2023</p>
                    {/*<p>№ {code}</p>*/}
                </div>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    position: "absolute",
                    left: "10px",
                    top: "9cm",
                    width: "225px",
                    color: "white",
                    fontSize: "1rem",
                    gap: 10
                }}>
                    <p style={{fontWeight: "600", textAlign: 'center', width: "100%"}}>Сертификат о повышении
                        квалификации</p>
                    <p style={{fontWeight: "600", textAlign: 'center', width: "100%"}}>№ {code}</p>
                </div>
                <div className={classes.uslovniki2032_main}>
                    <p style={{fontSize: "3rem", fontWeight: "800"}}>СЕРТИФИКАТ</p>
                    <Flex gap={20} align={"center"} justify={"center"} vertical style={{width: "100%"}}>
                        <p style={{fontSize: "1.5rem", fontWeight: "700"}}>Настоящий сертификат подтверждает, что</p>
                        <p className={classes.uslovniki2032_name}
                           style={{fontSize: "2.2rem", fontWeight: "700"}}>{name}</p>
                        <p style={{fontSize: "1.5rem", textAlign: "center"}}>с 6 марта по 18 марта 2023 года</p>
                        <p style={{fontSize: "1.5rem", textAlign: "center"}}>обучался(ась) на курсах повышения
                            квалификации в Центре компетенций и совершенства по программе "Современные методы и
                            инновационные технологии обучения" по направлению</p>
                        <p style={{fontSize: "1.5rem", textAlign: "center"}}><span
                            style={{fontWeight: "700"}}>{course}</span></p>
                        <p style={{fontSize: "1.5rem", textAlign: "center"}}>в объеме <span
                            style={{fontWeight: "700"}}>{hours}</span> академических часа.</p>

                    </Flex>
                    <Flex justify={"space-between"} align={"center"} gap={20} style={{width: "100%"}}>
                        <div>
                            <p style={{fontSize: "1.2rem", fontWeight: "700"}}></p>
                            <p style={{fontSize: "1.2rem", fontWeight: "700"}}></p>
                        </div>
                        <p style={{fontSize: "1.2rem", fontWeight: "700"}}></p>
                    </Flex>
                </div>
            </Flex>
        </Flex>
    );
};

export default DekanatCert2023;