import React from 'react';
import {Flex, message, Tabs} from "antd";
import {useDispatch} from "react-redux";
import GlobalValueList from "./GlobalValueList/GlobalValueList";
import GlobalValueCreate from "./GlobalValueCreate/GlobalValueCreate";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";
import MyText from "../../../../components/UI/Text/MyText";

const GlobalValuePage = () => {
    const dispatch = useDispatch();

    const onChangeTabs = async (key) => {
        // if (key === '1') {
        //     await dispatch(getAllGlobalValues());
        // }
    };

    const tabItems = [
        {
            key: '1',
            label: <IntlMessage id={'list'}/>,
            children: <GlobalValueList/>,
        },
        {
            key: '2',
            label: <IntlMessage id={'creation'}/>,
            children: <GlobalValueCreate/>,
        },

    ]

    return (
        <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} vertical gap={20}>
            <MyText uppercase strong size={"large"}><IntlMessage id={'globalData'}/></MyText>
            <Tabs style={{width: "100%"}} defaultActiveKey={"1"} type="card" items={tabItems} onChange={onChangeTabs}/>
        </Flex>
    );
};

export default GlobalValuePage;