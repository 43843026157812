import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Input, Select, message, Flex} from 'antd';
import {clrs} from '../../../../../constants/colors';
import ApplicationService from '../../../../../services/ApplicationService';
import classes from '../application.module.css';
import {LocalName} from '../../../../../utils/LocalName';
import FormItem from 'antd/es/form/FormItem';
import IntlMessage, {IntlMessageText} from "../../../../../components/IntlMessage/IntlMessage";
import GreyButton from "../../../../../components/UI/Buttons/GreyButton";


const ApplicationCreate = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [categories, setCategories] = useState([]);

    const name = Form.useWatch('name', form);
    const email = Form.useWatch('email', form);
    const telNumber = Form.useWatch('telNumber', form);
    const categoryId = Form.useWatch('categoryId', form);

    const categoriesList = useSelector((state) => state.categoriesList);

    useEffect(() => {
        setCategories(
            categoriesList.categories.map((category) => {
                return {value: category.id, label: LocalName.getName(category)};
            })
        );
    }, [categoriesList.categories]);

    function handleSubmit() {
        form.validateFields()
            .then(async () => {
                message.loading(<IntlMessage id={'searching'}/>, 0)
                await ApplicationService.saveApplication(
                    name,
                    email,
                    telNumber,
                    categoryId
                )
                    .then(() => {
                        message.destroy();
                        message.success(<IntlMessage id={'success'}/>, 5)
                        form.resetFields();
                    })
                    .catch(() => {
                        message.destroy();
                        message.error(<IntlMessage id={'error'}/>, 5)
                    })


            })
            .catch(() => {
                console.log("Validation error!");
            });
    }


    return (
        <Form form={form} className={classes.form}>
            <FormItem rules={[{required: true, message: <IntlMessage id={'bannerFormNameError'}/>}]}
                      className={classes.formInputBlock} name="name">
                <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "name"})}/>
            </FormItem>
            <FormItem rules={[{required: true, message: <IntlMessage id={'bannerFormEmailError'}/>}]}
                      className={classes.formInputBlock} name="email">
                <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "email"})}/>
            </FormItem>
            <FormItem rules={[{required: true, message: <IntlMessage id={'bannerFormTelError'}/>}]}
                      className={classes.formInputBlock} name="telNumber">
                <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "telNumber"})}/>
            </FormItem>
            <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                      className={classes.formInput} style={{width: '100%', padding: 0}} name="categoryId">
                <Select options={categories} placeholder={IntlMessageText.getText({id: "categoryCourse"})}/>
            </FormItem>
            <FormItem className={classes.formInputBlock}>
                <Flex align={"center"} justify={"center"} style={{width: "100%"}}>
                    <GreyButton onClick={handleSubmit} backgroundColor={clrs.blackerBlue} fontColor={clrs.white}>
                        <IntlMessage id={'send'}/>
                    </GreyButton>
                </Flex>
            </FormItem>
        </Form>
    );
};

export default ApplicationCreate;


