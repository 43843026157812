import React, {useEffect, useState} from 'react';
import classes from "./template.module.css";
import {useWindowSize} from "@uidotdev/usehooks";
import aituLogo from "./images/white_logo_aitu.png";
import it_summer_logo from "./images/it_summer_camp.png";
import aitu_half from "./images/half_aitu.png";
import {Flex} from "antd";

const Certs20222023 = ({certificate}) => {
    const [name, setName] = useState(null);
    const [code, setCode] = useState(null);
    const [grade, setGrade] = useState(null);
    const [group, setGroup] = useState(null);

    const size = useWindowSize();

    useEffect(() => {
        setName(certificate?.oldCertCharacs.find(charac => charac.label === '{full_name}')?.value)
        setCode(certificate?.code)
        setGrade(certificate?.oldCertCharacs.find(charac => charac.label === '{grade}')?.value)
        setGroup(certificate?.oldCertCharacs.find(charac => charac.label === '{group}')?.value)
    }, [certificate]);
    if (name === null) {
        return null;
    }
    if (code === null) {
        return null;
    }
    if (grade === null) {
        return null;
    }
    if (group === null) {
        return null;
    }
    return (
        <Flex style={{width: "100%", position: "relative", padding: "120px 20px 20px 20px", overflowX: "auto"}}
              align={"center"}
              justify={size.width <= 1200 ? "flex-start" : "center"}>
            <Flex className={classes.blockTemplate} gap={50}
                  style={{width: "100%", maxWidth: 1440, position: "relative", flexDirection: "column"}}
                  vertical align={"center"}
                  justify={"center"}>
                <div className={classes.gradient2}></div>
                <div className={classes.uslovniki2032_aituLogoWhite}>
                    <img src={aituLogo} alt=""/>
                </div>
                <div className={classes.lager2023_aitu_half_logo}>
                    <img src={aitu_half} alt=""/>
                </div>
                <p className={classes.lager2023_certificateTxt}>CERTIFICATE</p>
                <div className={classes.lager2023_main}>
                    <p style={{fontSize: "2rem", fontWeight: "400"}}>This is to certify that</p>
                    <p className={classes.uslovniki2032_name}
                       style={{fontSize: "2.5rem", fontWeight: "700"}}>{name}</p>
                    <p style={{fontSize: "2rem", fontWeight: "400"}}>completed <span
                        style={{fontWeight: "700"}}>{group}</span> course with <span style={{fontWeight: "700"}}>Exit Test</span> score
                        of <span style={{fontWeight: "700"}}>{grade}</span></p>
                    <Flex gap={20} align={"center"} justify={"space-between"} style={{
                        width: "calc(100% - 100px)",
                        position: "absolute",
                        left: "100px",
                        bottom: "45px",
                    }}>
                        <p style={{fontSize: "1.2rem"}}>{code}</p>
                        <p style={{fontSize: "1.2rem"}}>Astana</p>
                        <p style={{fontSize: "1.2rem", width: "5rem"}}></p>
                    </Flex>
                </div>
            </Flex>
        </Flex>
    );
};

export default Certs20222023;