import React, {useEffect, useState} from 'react';
import classes from './CreateCourse.module.css';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Flex, Form, Input, message, Select} from "antd";
import BlockLoading from "../../../../../components/LoadingComponents/BlockLoading";
import FormItem from "antd/es/form/FormItem";
import {clrs} from "../../../../../constants/colors";
import {resetCourseState, saveCourse} from "../../../../../store/slices/CourseSlice";
import {LocalName} from "../../../../../utils/LocalName";
import {getAllCategories} from "../../../../../store/slices/CategoriesListSlice";
import IntlMessage, {IntlMessageText} from "../../../../../components/IntlMessage/IntlMessage";
import GreyButton from "../../../../../components/UI/Buttons/GreyButton";

const CreateCourse = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [form] = Form.useForm();
    const nameEng = Form.useWatch('nameEng', form);
    const nameRu = Form.useWatch('nameRu', form);
    const nameKz = Form.useWatch('nameKz', form);
    const categoryId = Form.useWatch('categoryId', form);
    const numberOfHours = Form.useWatch('numberOfHours', form);
    const price = Form.useWatch('price', form);

    const categoriesList = useSelector(state => state.categoriesList);
    const course = useSelector(state => state.course);

    const [categories, setCategories] = useState([]);

    function handleSubmit() {
        form.validateFields()
            .then(async () => {
                await dispatch(saveCourse({nameEng, nameRu, nameKz, categoryId, numberOfHours, price}));
            })
            .catch(() => {
                console.log("Validation error!")
            })
    }

    useEffect(() => {
        dispatch(getAllCategories());
    }, []);

    useEffect(() => {
        setCategories(
            categoriesList.categories.map(category => {
                return { value: category.id, label: LocalName.getName(category) }
            })
        )
    }, [categoriesList.categories]);

    useEffect(() => {
        if (course.error !== null) {
            message.error(<IntlMessage id={'error'}/>, 5);
            dispatch(resetCourseState())
        }
    }, [course.error]);

    useEffect(() => {
        if (course.data !== null) {
            message.success(<IntlMessage id={'courseCreated'}/>, 5);
            form.resetFields();
            dispatch(resetCourseState())
        }
    }, [course.data]);

    return (
        <Form form={form} className={classes.form} initialValues={{
            price: 0,
            numberOfHours: 0
        }}>
            <BlockLoading isLoading={course.isLoading}/>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'nameEng'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="nameEng">
                <Input rootClassName={classes.formInput} />
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'nameRu'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="nameRu">
                <Input rootClassName={classes.formInput} />
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'nameKz'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="nameKz">
                <Input rootClassName={classes.formInput} />
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'category'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="categoryId">
                <Select
                    options={categories}
                />
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'numberOfHours'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="numberOfHours">
                <Input type={'number'} min={0} rootClassName={classes.formInput} />
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'price'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="price">
                <Input type={'number'} min={0} rootClassName={classes.formInput} />
            </FormItem>
            <FormItem className={classes.formInputBlock}>
                <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} gap={20}>
                    <GreyButton onClick={handleSubmit} backgroundColor={clrs.blackerBlue} fontColor={clrs.white}>
                        <IntlMessage id={'create'}/>
                    </GreyButton>
                </Flex>
            </FormItem>
        </Form>
    );
};

export default CreateCourse;