import React, {useEffect, useState} from 'react';
import classes from "./template.module.css";
import aituLogo from "./images/white_logo_aitu.png";
import aitu_half from "./images/half_aitu.png";
import back from './images/microKvaBack.png';
import {useWindowSize} from "@uidotdev/usehooks";
import {Flex} from "antd";

const MicroKva2023 = ({certificate}) => {
    const [name, setName] = useState(null);
    const [code, setCode] = useState(null);
    const [name_microKva, setNameMicroKva] = useState(null);
    const [dates, setDates] = useState(null);
    const [credits, setCredits] = useState(null);
    const [hours, setHours] = useState(null);
    const [engName, setEngName] = useState(null);

    const size = useWindowSize();

    useEffect(() => {
        setName(certificate?.oldCertCharacs.find(charac => charac.label === '{full_name}')?.value)
        setCode(certificate?.code)
        setNameMicroKva(certificate?.oldCertCharacs.find(charac => charac.label === '{name_microKva}')?.value)
        setDates(certificate?.oldCertCharacs.find(charac => charac.label === '{dates}')?.value)
        setCredits(certificate?.oldCertCharacs.find(charac => charac.label === '{credits}')?.value)
        setHours(certificate?.oldCertCharacs.find(charac => charac.label === '{hours}')?.value)
        setEngName(certificate?.oldCertCharacs.find(charac => charac.label === '{name_surname}')?.value)
    }, [certificate]);
    if (name === null) {
        return null;
    }
    if (code === null) {
        return null;
    }
    if (name_microKva === null) {
        return null;
    }
    if (dates === null) {
        return null;
    }
    if (credits === null) {
        return null;
    }
    if (hours === null) {
        return null;
    }
    if (engName === null) {
        return null;
    }

    return (
        <Flex style={{width: "100%", position: "relative", padding: "120px 20px 20px 20px", overflowX: "auto"}}
              align={"center"}
              justify={size.width <= 1200 ? "flex-start" : "center"}>
            <Flex className={classes.blockTemplate} gap={50}
                  style={{width: "100%", maxWidth: 1440, position: "relative", flexDirection: "column"}}
                  vertical align={"center"}
                  justify={"center"}>
                <div className={classes.microKva2023_backBlock}>
                    <img src={back} alt=""/>
                </div>
                <div className={classes.microKva2023_main}>
                    <Flex align={"flex-start"} justify={"flex-start"} style={{width: "100%"}} gap={20}>
                        <p style={{fontSize: "1.5rem", fontWeight: "500"}}>This certificate confirms that</p>
                    </Flex>
                    <p className={classes.uslovniki2032_name}
                       style={{fontSize: "3rem", fontWeight: "600"}}>{engName}</p>
                    <p style={{fontSize: "1.5rem", fontWeight: "500"}}>from {dates}</p>
                    <p style={{fontSize: "1.5rem", fontWeight: "500"}}>has successfully completed study at Astana IT
                        University</p>
                    <p style={{fontSize: "1.5rem", fontWeight: "500"}}>on the educational program of
                        micro-qualification</p>
                    <p style={{fontSize: "1.5rem", fontWeight: "500"}}>{name_microKva}</p>
                    <p style={{fontSize: "1.5rem", fontWeight: "500"}}>in the amount of {credits} credits
                        ({hours} academic hours)</p>
                    <Flex gap={20} align={"center"} justify={"space-between"} style={{
                        width: "calc(100% - 100px)",
                        position: "absolute",
                        left: "100px",
                        bottom: "45px",
                    }}>
                        <p style={{fontSize: "1.2rem"}}>{code}</p>
                        <p style={{fontSize: "1.2rem"}}>Astana</p>
                        <p style={{fontSize: "1.2rem", width: "5rem"}}></p>
                    </Flex>
                </div>
            </Flex>
        </Flex>
    );
};

export default MicroKva2023;