import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import CategoryService from "../../services/CategoryService";
import OrderService from "../../services/OrderService";
import TeacherService from "../../services/TeacherService";

let initialState = {
    teachers: [],
    isLoading: false,
    error: null,
}
export const getAllTeachersForOrder = createAsyncThunk(
    'getAllTeachersForOrder',
    async ({categoryId}, {getState, rejectWithValue, dispatch}) => {
        try {
            const response = await TeacherService.getAllTeachersByCategory(categoryId);
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

const teachersListForOrderSlice = createSlice({
    name: 'teachersListForOrderSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllTeachersForOrder.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllTeachersForOrder.fulfilled, (state, action) => {
                state.isLoading = false;
                state.teachers = action.payload.map(teacher => {
                    return {
                        value: teacher.id,
                        label: teacher.fullName
                    }
                });
            })
            .addCase(getAllTeachersForOrder.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                state.teachers = [];
            })
    }
});

export const {} = teachersListForOrderSlice.actions;

export default teachersListForOrderSlice.reducer;