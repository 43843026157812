import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import ContractService from "../../services/ContractService";

let initialState = {
    categories: [],
    isLoading: false,
    error: null,
}
export const getAllContractCategories = createAsyncThunk(
    'getAllContractCategories',
    async (_, {getState, rejectWithValue, dispatch}) => {
        try {
            const response = await ContractService.getAllContractCategories();
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

const contractCategoriesListSlice = createSlice({
    name: 'contractCategoriesListSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllContractCategories.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllContractCategories.fulfilled, (state, action) => {
                state.isLoading = false;
                state.categories = action.payload;
            })
            .addCase(getAllContractCategories.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                state.categories = [];
            })
    }
});

export const {} = contractCategoriesListSlice.actions;

export default contractCategoriesListSlice.reducer;