import {ACCESS_TOKEN} from "../constants/auth";
import {API_BASE_URL} from "../constants/api";
import axios from "axios";

const CourseService = {};
CourseService.saveCourse = async function (nameEng, nameRu, nameKz, categoryId, numberOfHours, price) {
    let data = new FormData();
    data.append("nameEng", nameEng);
    data.append("nameRu", nameRu);
    data.append("nameKz", nameKz);
    data.append("categoryId", categoryId);
    data.append("numberOfHours", numberOfHours);
    data.append("price", price);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/admin/course/save",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

CourseService.deleteCourseById = async function (id) {

    return axios({
        method: "delete",
        url: API_BASE_URL + `/api/admin/course/delete/${id}`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}



export default CourseService;