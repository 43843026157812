import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    currentPage: 1,
    pageSize: 100,
};

export const contractListTableControllerSlice = createSlice({
    name: 'contractListTableControllerSlice',
    initialState,
    reducers: {
        changeCurrentPage(state, action) {
            state.currentPage = action.payload.page;
            state.pageSize = action.payload.pageSize;
        },
    },
});

export const { changeCurrentPage } = contractListTableControllerSlice.actions;

export default contractListTableControllerSlice.reducer;