import React from 'react';
import {Flex, message, Tabs} from "antd";
import {useDispatch} from "react-redux";
import ContractsList from "./List/ContractsList";
import ContractCreate from "./Creation/ContractCreate";
import CreateContractCategory from "./CategoryCreation/CreateContractCategory";
import {getAllContractCategories} from "../../../../store/slices/ContractCategoriesListSlice";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";
import MyText from "../../../../components/UI/Text/MyText";

const ContractsPage = () => {
    const dispatch = useDispatch();

    const onChangeTabs = async (key) => {
        if (key === '2') {
            dispatch(getAllContractCategories());
        }
    };

    const tabItems = [
        {
            key: '1',
            label: <IntlMessage id={'list'}/>,
            children: <ContractsList/>,
        },
        {
            key: '2',
            label: <IntlMessage id={'creation'}/>,
            children: <ContractCreate/>,
        },
        {
            key: '3',
            label: <IntlMessage id={'createCategory'}/>,
            children: <CreateContractCategory/>,
        },
    ]

    return (
        <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} vertical gap={20}>
            <MyText uppercase strong size={"large"}><IntlMessage id={'contracts'}/></MyText>
            <Tabs style={{width: "100%"}} defaultActiveKey={"1"} type="card" items={tabItems} onChange={onChangeTabs}/>
        </Flex>
    );
};

export default ContractsPage;