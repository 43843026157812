import React from 'react';
import img1 from '../../../../images/1с.png';
import img2 from '../../../../images/amazon.png';
import img3 from '../../../../images/apple.png';
import img4 from '../../../../images/britishCouncil.png';
import img5 from '../../../../images/cisco.png';
import img6 from '../../../../images/epam.png';
import img7 from '../../../../images/htmlAcademy.png';
import img8 from '../../../../images/huawei.png';
import img9 from '../../../../images/kaspersky.png';
import img10 from '../../../../images/kazaktel.png';
import img11 from '../../../../images/microsoft.png';
import img12 from '../../../../images/123.png';
import img13 from '../../../../images/oracle.png';
import img14 from '../../../../images/samsung.png';
import img15 from '../../../../images/234.png';
import img16 from '../../../../images/atameken.png';
import img17 from '../../../../images/imisp.png';
import img18 from '../../../../images/adminAstana.png';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

import classes from './PartnersSection.module.css';
import {useWindowSize} from "@uidotdev/usehooks";
import {Flex} from "antd";
import {clrs} from "../../../../constants/colors";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";

const PartnersSection = () => {

    const size = useWindowSize();

    const options = {
        type: 'loop',
        perPage: size.width > 1200 ? 6 : size.width > 1000 ? 5 : size.width > 768 ? 4 : size.width > 575 ? 3 : 2,
        perMove: 1,
        loop: true,
        autoplay: true,
        updateOnMove: true,
        interval: 5000,
        arrows: false,
        pagination: false,
        drag: false,
        pauseOnHover: false,
        pauseOnFocus: false,
        lazyLoad: true,
        speed: 10000,
        width: "100%",
        gap: 50,
        easing: 'linear'
    };
    return (
        <div className={classes.main}>
            <Flex align={"center"} justify={"center"} vertical gap={50} style={{
                backgroundColor: clrs.blackerBlue,
                width: "100%",
                position: "relative",
                padding: size.width > 1024 ? 50 : "50px 10px",
                borderRadius: 25
            }}>
                <p className={classes.headerText}><IntlMessage id={'ourPartners'}/></p>
                <Splide options={ options } >
                    <SplideSlide className={classes.eachSlide}>
                        <img src={img1} alt="Image 1"/>
                    </SplideSlide>
                    <SplideSlide className={classes.eachSlide}>
                        <img src={img2} alt="Image 2"/>
                    </SplideSlide>
                    <SplideSlide className={classes.eachSlide}>
                        <img src={img3} alt="Image 2"/>
                    </SplideSlide>
                    <SplideSlide className={classes.eachSlide}>
                        <img src={img4} alt="Image 2"/>
                    </SplideSlide>
                    <SplideSlide className={classes.eachSlide}>
                        <img src={img5} alt="Image 2"/>
                    </SplideSlide>
                    <SplideSlide className={classes.eachSlide}>
                        <img src={img6} alt="Image 2"/>
                    </SplideSlide>
                </Splide>
                <Splide options={ options } >
                    <SplideSlide className={classes.eachSlide}>
                        <img src={img7} alt="Image 1"/>
                    </SplideSlide>
                    <SplideSlide className={classes.eachSlide}>
                        <img src={img8} alt="Image 2"/>
                    </SplideSlide>
                    <SplideSlide className={classes.eachSlide}>
                        <img src={img9} alt="Image 2"/>
                    </SplideSlide>
                    <SplideSlide className={classes.eachSlide}>
                        <img src={img10} alt="Image 2"/>
                    </SplideSlide>
                    <SplideSlide className={classes.eachSlide}>
                        <img src={img11} alt="Image 2"/>
                    </SplideSlide>
                    <SplideSlide className={classes.eachSlide}>
                        <img src={img12} alt="Image 2"/>
                    </SplideSlide>
                </Splide>
                <Splide options={ options } >
                    <SplideSlide className={classes.eachSlide}>
                        <img src={img13} alt="Image 1"/>
                    </SplideSlide>
                    <SplideSlide className={classes.eachSlide}>
                        <img src={img14} alt="Image 2"/>
                    </SplideSlide>
                    <SplideSlide className={classes.eachSlide}>
                        <img src={img15} alt="Image 2"/>
                    </SplideSlide>
                    <SplideSlide className={classes.eachSlide}>
                        <img src={img16} alt="Image 2"/>
                    </SplideSlide>
                    <SplideSlide className={classes.eachSlide}>
                        <img src={img17} alt="Image 2"/>
                    </SplideSlide>
                    <SplideSlide className={classes.eachSlide}>
                        <img src={img18} alt="Image 2"/>
                    </SplideSlide>
                </Splide>
            </Flex>
        </div>
    );
};

export default PartnersSection;