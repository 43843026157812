import React, {useState} from 'react';
import {Flex, Form, Input, message} from "antd";
import classes from './cert.module.css';
import FormItem from "antd/es/form/FormItem";
import {clrs} from "../../constants/colors";
import CertificateService from "../../services/CertificateService";
import Uslovniki2023 from "./templates/Uslovniki2023";
import Lager2023 from "./templates/Lager2023";
import DekanatCert2023 from "./templates/DekanatCert2023";
import Lager2022 from "./templates/Lager2022";
import GeneralEnglish2023 from "./templates/GeneralEnglish2023";
import Certs20222023 from "./templates/Certs20222023";
import Certs2 from "./templates/Certs2";
import Foundation2023 from "./templates/Foundation2023";
import CertCourses2023 from "./templates/CertCourses2023";
import Kaztelecom from "./templates/Kaztelecom";
import MicroKva2023 from "./templates/MicroKva2023";
import IntlMessage, {IntlMessageText} from "../../components/IntlMessage/IntlMessage";
import Header from "../components/Header/Header";
import MyText from "../../components/UI/Text/MyText";
import Footer from "../components/Footer/Footer";
import GreyButton from "../../components/UI/Buttons/GreyButton";

const CertificatesPage = () => {

    const [form] = Form.useForm();
    const certCode = Form.useWatch('certCode', form);

    const [isLoading, setIsLoading] = useState(false);

    const [certificate, setCertificate] = useState(null);

    function handleCheck() {
        form.validateFields()
            .then(async () => {
                setIsLoading(true);
                message.loading(<IntlMessage id={'searching'}/>, 0);
                await CertificateService.getCertificateByCode(certCode)
                    .then((r) => {
                        message.destroy();
                        message.success(<IntlMessage id={'success'}/>, 5);
                        console.log(r.data)
                        setCertificate(r.data);
                    })
                    .catch(() => {
                        message.destroy();
                        message.error(<IntlMessage id={'error'}/>, 5);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            })
            .catch(() => {
                console.log("Validation error!")
            })
    }

    return (
        <div>
            <Header/>
            <Flex style={{width: "100%", position: "relative", padding: "120px 20px 20px 20px"}} align={"center"} justify={"center"}>
                <Flex gap={50} style={{width: "100%", maxWidth: 1440, position: "relative", minHeight: "calc(100vh - 300px)"}} vertical align={"center"} justify={"center"}>
                    <MyText uppercase strong size={"large"}><IntlMessage id={'verificationCertificates'}/></MyText>
                    <Form form={form} className={classes.formBlock}>
                        <FormItem rules={[{
                            required: true,
                            message: <IntlMessage id={'requiredField'}/>,
                        },
                        ]} label={<IntlMessage id={'certCode'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="certCode">
                            <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: 'certCode'})}/>
                        </FormItem>
                        <FormItem className={classes.formInputBlock}>
                            <Flex align={"center"} justify={"center"} style={{width: "100%"}} gap={20}>
                                <GreyButton onClick={handleCheck} backgroundColor={clrs.blackerBlue} fontColor={clrs.white}><IntlMessage id={'check'}/></GreyButton>
                            </Flex>
                        </FormItem>
                    </Form>
                    <Templates certificate={certificate}/>
                </Flex>
            </Flex>
            <Footer/>
        </div>
    );
};

export default CertificatesPage;

const Templates = ({certificate}) => {
    if (certificate === null) {
        return '';
    }
    if (certificate === undefined) {
        return '';
    }
    if (certificate?.template === "uslovniki2023" ) {
        return <Uslovniki2023 certificate={certificate}/>
    }
    if (certificate?.template === "letniki2023" ) {
        return <Uslovniki2023 certificate={certificate}/>
    }
    if (certificate?.template === "23lager3" ) {
        return <Lager2023 certificate={certificate}/>
    }
    if (certificate?.template === "23lager2" ) {
        return <Lager2023 certificate={certificate}/>
    }
    if (certificate?.template === "23lager1" ) {
        return <Lager2023 certificate={certificate}/>
    }
    if (certificate?.template === "dekanatCert2023" ) {
        return <DekanatCert2023 certificate={certificate}/>
    }
    if (certificate?.template === "kaztelecom" ) {
        return <Kaztelecom certificate={certificate}/>
    }
    if (certificate?.template === "lager4" ) {
        return <Lager2022 certificate={certificate}/>
    }
    if (certificate?.template === "lager3" ) {
        return <Lager2022 certificate={certificate}/>
    }
    if (certificate?.template === "lager2" ) {
        return <Lager2022 certificate={certificate}/>
    }
    if (certificate?.template === "lager1" ) {
        return <Lager2022 certificate={certificate}/>
    }
    if (certificate?.template === "generalEnglish2023" ) {
        return <GeneralEnglish2023 certificate={certificate}/>
    }
    if (certificate?.template === "certs2022_2023" ) {
        return <Certs20222023 certificate={certificate}/>
    }
    if (certificate?.template === "certs2022_2023_2" ) {
        return <Certs2 certificate={certificate}/>
    }
    if (certificate?.template === "microKva2023" ) {
        return <MicroKva2023 certificate={certificate}/>
    }
    if (certificate?.template === "certCourses2023" ) {
        return <CertCourses2023 certificate={certificate}/>
    }
    if (certificate?.template === "found2023" ) {
        return <Foundation2023 certificate={certificate}/>
    }
    return (
        <div>

        </div>
    );
};