import React, {useEffect} from 'react';
import {Flex, Form, Input, message, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import BlockLoading from "../../../../../components/LoadingComponents/BlockLoading";
import classes from './createCategory.module.css';
import FormItem from "antd/es/form/FormItem";
import {clrs} from "../../../../../constants/colors";
import {resetCategoryState, saveCategory} from "../../../../../store/slices/CategorySlice";
import IntlMessage, {IntlMessageText} from "../../../../../components/IntlMessage/IntlMessage";
import GreyButton from "../../../../../components/UI/Buttons/GreyButton";

const CreateCategory = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const nameEng = Form.useWatch('nameEng', form);
    const nameRu = Form.useWatch('nameRu', form);
    const nameKz = Form.useWatch('nameKz', form);
    const formType = Form.useWatch('formType', form);

    const category = useSelector(state => state.category);

    function handleSubmit() {
        form.validateFields()
            .then(async () => {
                await dispatch(saveCategory({nameEng, nameRu, nameKz, formType}));
            })
            .catch(() => {
                console.log("Validation error!")
            })
    }

    useEffect(() => {
        if (category.error !== null) {
            message.error(<IntlMessage id={'error'}/>, 5);
            dispatch(resetCategoryState())
        }
    }, [category.error]);

    useEffect(() => {
        if (category.data !== null) {
            message.success(<IntlMessage id={'categoryCreated'}/>, 5);
            form.resetFields();
            dispatch(resetCategoryState())
        }
    }, [category.data]);


    return (
        <Form form={form} className={classes.form}>
            <BlockLoading isLoading={category.isLoading}/>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'nameEng'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="nameEng">
                <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "nameEng"})} />
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'nameRu'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="nameRu">
                <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "nameRu"})} />
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'nameKz'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="nameKz">
                <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "nameKz"})} />
            </FormItem>
            <FormItem label={<IntlMessage id={'formType'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="formType">
                <Select
                    options={[
                        {
                            value: 'FOUNDATION',
                            label: 'FOUNDATION TYPE'
                        }
                    ]}
                />
            </FormItem>
            <FormItem className={classes.formInputBlock}>
                <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} gap={20}>
                    <GreyButton onClick={handleSubmit} backgroundColor={clrs.blackerBlue} fontColor={clrs.white}>
                        <IntlMessage id={'create'}/>
                    </GreyButton>
                </Flex>
            </FormItem>
        </Form>
    );
};

export default CreateCategory;