import React, {useEffect, useState} from 'react';
import {Button, Flex, message, Popconfirm, Table} from "antd";
import {useNavigate} from "react-router-dom";
import TeacherService from "../../../../../services/TeacherService";
import IntlMessage from "../../../../../components/IntlMessage/IntlMessage";
import {LocalName} from "../../../../../utils/LocalName";
import OrderService from "../../../../../services/OrderService";
import {clrs} from "../../../../../constants/colors";
import RedButton from "../../../../../components/UI/Buttons/RedButton";
import GreyButton from "../../../../../components/UI/Buttons/GreyButton";
import {getAllApplicationsPageable} from "../../../../../store/slices/ApplicationsListSlice";
import BlackerBlueButton from "../../../../../components/UI/Buttons/BlackerBlueButton";
import GreenButton from "../../../../../components/UI/Buttons/GreenButton";

const OrderList = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(30);

    const [orders, setOrders] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const [initialLoading, setInitialLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        handleFetchData();
        if (initialLoading) {
            setInitialLoading(false);
        }
    }, [currentPage]);

    useEffect(() => {
        if (currentPage === 1) {
            if (!initialLoading) {
                handleFetchData();
            }
        } else {
            setCurrentPage(1);
        }

    }, [pageSize]);

    async function handleFetchData() {
        setLoading(true);
        await OrderService.getAllOrdersPageable(currentPage, pageSize)
            .then((r) => {
                setOrders(r.data);
                setHasMore(r.hasMore);
                setInitialLoading(false);
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const handlePaginationChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    }

    function triggerDownload(blob, filename) {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); // Set the download attribute with a filename
        document.body.appendChild(link);
        link.click();

        // Clean up and revoke the URL after download
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }

    async function handleDownloadOrder(orderId) {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        await OrderService.downloadOrderById(orderId)
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);

                triggerDownload(r.data, "ORDER.pdf")
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
    }

    async function handleDownloadStudentsList() {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        await OrderService.getStudentsListAsExcel()
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);

                triggerDownload(r.data, "students.xlsx")
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
    }

    async function handleDeleteOrder(orderId) {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        await OrderService.deleteOrder(orderId)
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);

                handleFetchData();
            })
            .catch(() => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
    }

    const columns = [
        {
            title: <IntlMessage id={'category'}/>,
            render: (_, record) => (
                <p>
                    {LocalName.getName(record.category)}
                </p>
            ),
            width: "15%"
        },
        {
            title: <IntlMessage id={'ordersPage.groups'}/>,
            render: (_, record) => (
                <p>
                    {record.groups.map((group, i) => {
                        return <span>{group.name}{i + 1 !== record.groups.length && ', '}</span>
                    })}
                </p>
            ),
        },
        {
            title: <IntlMessage id={'actions'}/>,
            render: (_, record) => (
                <Flex gap={20} wrap={"wrap"} align={"flex-start"} justify={"flex-start"}>
                    <Button onClick={() => {handleDownloadOrder(record.id)}} type={"primary"}><IntlMessage id={'download'}/></Button>
                    <Popconfirm
                        title={<IntlMessage id={'delete'}/>}
                        description={<IntlMessage id={'formPage.youSureToDelete'}/>}
                        onConfirm={() => {handleDeleteOrder(record.id)}}
                        okText={<IntlMessage id={'yes'}/>}
                        cancelText={<IntlMessage id={'no'}/>}
                    >
                        <Button danger type={"primary"}><IntlMessage id={'delete'}/></Button>
                    </Popconfirm>

                </Flex>
            ),
            width: "15%"
        },
    ];

    return (
        <Flex style={{width: "100%", position: "relative", minWidth: 800}} align={"flex-start"} justify={"flex-start"} gap={20} vertical>
            <Flex style={{width: "100%"}} align={"center"} justify={"center"} gap={20} wrap={"wrap"}>
                <GreyButton onClick={() => {
                    handleFetchData()
                }} backgroundColor={clrs.whiter} fontColor={clrs.black}><IntlMessage id={'update'}/></GreyButton>
                <GreyButton onClick={handleDownloadStudentsList} backgroundColor={clrs.whiter} fontColor={clrs.black}><IntlMessage id={'download'}/></GreyButton>

            </Flex>
            <Table
                bordered
                columns={columns}
                dataSource={orders}
                pagination={{
                    current: currentPage,
                    total: hasMore
                        ? currentPage * pageSize + 1
                        : currentPage * pageSize,
                    defaultPageSize: pageSize,
                    showSizeChanger: true,
                    pageSize: pageSize,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '100'],
                    onChange: handlePaginationChange,
                }}
                rowKey={record => record.id}
                loading={isLoading}
                style={{
                    width: "100%",
                }}
                scroll={{ x: 800 }}
            />
        </Flex>
    );
};

export default OrderList;