import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import UserService from "../../services/UserService";

let initialState = {
    users: [],
    isLoading: false,
    hasMore: false,
    error: null,
}
export const getAllUsersPageable = createAsyncThunk(
    'getAllUsersPageable',
    async ({ page, limit }, {getState, rejectWithValue, dispatch}) => {
        try {
            return await UserService.getUsers(page, limit);
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

const usersListSlice = createSlice({
    name: 'usersListSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllUsersPageable.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllUsersPageable.fulfilled, (state, action) => {
                state.isLoading = false;
                state.users = action.payload.data;
                state.hasMore = action.payload.hasMore;
            })
            .addCase(getAllUsersPageable.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                state.users = [];
                state.hasMore = false;
            })
    }
});

export const {} = usersListSlice.actions;

export default usersListSlice.reducer;