import React from 'react';
import classes from './NotFound.module.css';
import {Flex, Result} from "antd";
import SteveBloxButton from "../../../../components/UI/Buttons/SteveBloxButton";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";

const NotFound = () => {
    return (
        <Flex align={"center"} justify={"center"} style={{width: "100%"}}>
            {/*<div className={classes.pyramid_loader}>*/}
            {/*    <div className={classes.wrapper}>*/}
            {/*        <span className={[classes.side, classes.side1].join(" ")}></span>*/}
            {/*        <span className={[classes.side, classes.side2].join(" ")}></span>*/}
            {/*        <span className={[classes.side, classes.side3].join(" ")}></span>*/}
            {/*        <span className={[classes.side, classes.side4].join(" ")}></span>*/}
            {/*        <span className={classes.shadow}></span>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <Result
                status="404"
                title="404"
                subTitle={<IntlMessage id={'notFoundPage'}/>}
            />
        </Flex>
    );
};

export default NotFound;