import React from 'react';
import {useDispatch} from "react-redux";
import {getAllCategories} from "../../../../store/slices/CategoriesListSlice";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";
import TeachersList from "../Teachers/List/TeachersList";
import TeachersCreate from "../Teachers/Creation/TeachersCreate";
import {Flex, Tabs} from "antd";
import MyText from "../../../../components/UI/Text/MyText";
import OrderCreate from "./Creation/OrderCreate";
import OrderList from "./List/OrderList";

const OrdersPage = () => {
    const dispatch = useDispatch();

    const onChangeTabs = async (key) => {
        if (key === '2') {
            dispatch(getAllCategories());
        }
    };

    const tabItems = [
        {
            key: '1',
            label: <IntlMessage id={'list'}/>,
            children: <OrderList/>,
        },
        {
            key: '2',
            label: <IntlMessage id={'creation'}/>,
            children: <OrderCreate/>,
        },
    ]

    return (
        <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} vertical gap={20}>
            <MyText uppercase strong size={"large"}><IntlMessage id={'orders'}/></MyText>
            <Tabs style={{width: "100%"}} defaultActiveKey={"1"} type="card" items={tabItems} onChange={onChangeTabs}/>
        </Flex>
    );
};

export default OrdersPage;