import React, {useEffect, useState} from 'react';
import {Flex, Form, Input, message, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {LocalName} from "../../../../../utils/LocalName";
import ContractService from "../../../../../services/ContractService";
import classes from "../../Courses/CreateCourse/CreateCourse.module.css";
import BlockLoading from "../../../../../components/LoadingComponents/BlockLoading";
import FormItem from "antd/es/form/FormItem";
import {clrs} from "../../../../../constants/colors";
import IntlMessage, {IntlMessageText} from "../../../../../components/IntlMessage/IntlMessage";
import GreyButton from "../../../../../components/UI/Buttons/GreyButton";

const ContractCreate = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const firstName = Form.useWatch('firstName', form);
    const lastName = Form.useWatch('lastName', form);
    const middleName = Form.useWatch('middleName', form);
    const categoryId = Form.useWatch('categoryId', form);

    const [categories, setCategories] = useState([]);
    const categoriesList = useSelector(state => state.contractCategoriesList);

    const [isLoadingCreate, setIsLoadingCreate] = useState(false);

    useEffect(() => {
        setCategories(
            categoriesList.categories.map(category => {
                return { value: category.id, label: LocalName.getName(category) }
            })
        )
    }, [categoriesList.categories]);

    function handleCreate() {
        form.validateFields()
            .then(async () => {
                message.loading(<IntlMessage id={'loading'}/>, 0);
                setIsLoadingCreate(true);
                await ContractService.saveContract(firstName, lastName, middleName, categoryId)
                    .then(() => {
                        message.destroy();
                        message.success(<IntlMessage id={'creation'}/>, 5);
                        form.resetFields();
                    })
                    .catch(() => {
                        message.destroy();
                        message.error(<IntlMessage id={'error'}/>, 5);
                    })
                    .finally(() => {
                        setIsLoadingCreate(false);
                    })
            })
            .catch(() => {
                console.log("Validation error!")
            })
    }

    const contractCreate = useSelector(state => state.contractCreate);

    return (
        <Form form={form} className={classes.form}>
            <BlockLoading isLoading={isLoadingCreate}/>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'firstName'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="firstName">
                <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "firstName"})} />
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'lastName'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="lastName">
                <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "lastName"})} />
            </FormItem>
            <FormItem label={<IntlMessage id={'middleName'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="middleName">
                <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "middleName"})} />
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'category'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="categoryId">
                <Select
                    options={categories}
                />
            </FormItem>
            <FormItem className={classes.formInputBlock}>
                <Flex align={"flex-start"} justify={"flex-start"} gap={20} style={{width: "100%"}}>
                    <GreyButton onClick={handleCreate} backgroundColor={clrs.blackerBlue} fontColor={clrs.white}><IntlMessage id={'create'}/></GreyButton>
                </Flex>
            </FormItem>
        </Form>
    );
};

export default ContractCreate;