import axios from "axios";
import {API_BASE_URL} from "../constants/api";
import {ACCESS_TOKEN} from "../constants/auth";
import CategoryService from "./CategoryService";

const ContractService = {};
ContractService.saveContract = async function (firstName, lastName, middleName, contractCategoryId) {
    let data = new FormData();
    data.append("firstName", firstName);
    data.append("lastName", lastName);
    if (middleName !== undefined) {
        data.append("middleName", middleName);
    }
    data.append("contractCategoryId", contractCategoryId);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/admin/contract/save",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

ContractService.getAllContractCategories = async function () {
    return axios({
        method: "get",
        url: API_BASE_URL + `/api/admin/contract-category/get`,
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

ContractService.saveContractCategory = async function (nameEng, nameRu, nameKz) {
    let data = new FormData();
    data.append("nameEng", nameEng);
    data.append("nameRu", nameRu);
    data.append("nameKz", nameKz);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/admin/contract-category/save",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

ContractService.changeStatus = async function (contractId, status) {
    let data = new FormData();
    data.append("status", status);

    return axios({
        method: "put",
        url: API_BASE_URL + `/api/admin/contract/${contractId}/change/status`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

ContractService.getStudentsListAsExcel = async function () {

    return axios({
        method: "get",
        url: API_BASE_URL + `/api/admin/contract/get/excel/1`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        },
        responseType: "blob"
    });
}

ContractService.getAllContractsPageable = async function (page, limit, name) {
    const response = await axios({
        method: "get",
        url: API_BASE_URL + `/api/admin/contract/get?page=${page - 1}&limit=${limit}${name == null || name == undefined ? '' : '&name=' + name}`,
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
    const data = response.data.list;
    const hasMore = response.data.hasMore;
    return { data, hasMore };
}


export default ContractService;