import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import CategoryService from "../../services/CategoryService";

let initialState = {
    categories: [],
    isLoading: false,
    error: null,
}
export const getAllCategories = createAsyncThunk(
    'getAllCategories',
    async (_, {getState, rejectWithValue, dispatch}) => {
        try {
            const response = await CategoryService.getAllCategories();
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

const categoriesListSlice = createSlice({
    name: 'categoriesListSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllCategories.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllCategories.fulfilled, (state, action) => {
                state.isLoading = false;
                state.categories = action.payload;
            })
            .addCase(getAllCategories.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                state.categories = [];
            })
    }
});

export const {} = categoriesListSlice.actions;

export default categoriesListSlice.reducer;