import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import FormService from "../../services/FormService";

let initialState = {
    isLoading: false,
    error: null,
}

export const saveForm = createAsyncThunk(
    'saveForm',
    async ({applicationId}, {getState, rejectWithValue, dispatch}) => {
        try {
            const response = await FormService.saveForm(applicationId);
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

const formSaveSlice = createSlice({
    name: 'formSaveSlice',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveForm.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(saveForm.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(saveForm.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});

export const {} = formSaveSlice.actions;

export default formSaveSlice.reducer;