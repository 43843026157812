import React from 'react';
import {useDispatch} from "react-redux";
import {getAllContractCategories} from "../../../../store/slices/ContractCategoriesListSlice";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";
import ContractsList from "../Contracts/List/ContractsList";
import ContractCreate from "../Contracts/Creation/ContractCreate";
import CreateContractCategory from "../Contracts/CategoryCreation/CreateContractCategory";
import {Flex, Tabs} from "antd";
import MyText from "../../../../components/UI/Text/MyText";
import TeachersList from "./List/TeachersList";
import TeachersCreate from "./Creation/TeachersCreate";
import {getAllCategories} from "../../../../store/slices/CategoriesListSlice";

const TeachersPage = () => {
    const dispatch = useDispatch();

    const onChangeTabs = async (key) => {
        if (key === '2') {
            dispatch(getAllCategories());
        }
    };

    const tabItems = [
        {
            key: '1',
            label: <IntlMessage id={'list'}/>,
            children: <TeachersList/>,
        },
        {
            key: '2',
            label: <IntlMessage id={'creation'}/>,
            children: <TeachersCreate/>,
        },
    ]

    return (
        <Flex style={{width: "100%"}} align={"flex-start"} justify={"flex-start"} vertical gap={20}>
            <MyText uppercase strong size={"large"}><IntlMessage id={'teachers'}/></MyText>
            <Tabs style={{width: "100%"}} defaultActiveKey={"1"} type="card" items={tabItems} onChange={onChangeTabs}/>
        </Flex>
    );
};

export default TeachersPage;