import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import CategoryService from "../../services/CategoryService";
import OrderService from "../../services/OrderService";

let initialState = {
    groupStudents: [],
}

const groupStudentsListForOrderSlice = createSlice({
    name: 'groupStudentsListForOrderSlice',
    initialState,
    reducers: {
        addStudent(state, action) {
            state.groupStudents.push(action.payload);
        },
        remoteStudent(state, action) {
            state.groupStudents = state.groupStudents.filter(groupStudent => groupStudent.student.id !== action.payload.student.id)
        },
    },
});

export const {addStudent, remoteStudent} = groupStudentsListForOrderSlice.actions;

export default groupStudentsListForOrderSlice.reducer;