import React, {useEffect} from 'react';

import {Flex, Tabs} from "antd";
import {useDispatch} from "react-redux";
import IntlMessage from "../../../../components/IntlMessage/IntlMessage";
import {getAllCategories} from "../../../../store/slices/CategoriesListSlice";
import MyText from "../../../../components/UI/Text/MyText";
import ApplicationList from "./List/ApplicationList";
import ApplicationCreate from "./Creation/ApplicationCreate";

const ApplicationsPage = () => {
    const dispatch = useDispatch();

    const onChangeTabs = (key) => {
        if (key === '2') {
            dispatch(getAllCategories());

        }
    };

    const tabItems = [
        {
            key: '1',
            label: <IntlMessage id={'list'}/>,
            children: <ApplicationList/>,
        },
        {
            key: '2',
            label: <IntlMessage id={'creation'}/>,
            children: <ApplicationCreate/>,
        },
    ]

    return (
        <Flex style={{width: "100%"}} gap={20} align={"flex-start"} justify={"flex-start"} vertical>
            <MyText uppercase strong><IntlMessage id={'applications'}/></MyText>
            <Tabs style={{width: "100%"}} defaultActiveKey={"1"} type="card" items={tabItems} onChange={onChangeTabs}/>
        </Flex>
    );
};

export default ApplicationsPage;