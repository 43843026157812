import {configureStore} from "@reduxjs/toolkit";
import application from './slices/ApplicationSlice';
import category from './slices/CategorySlice';
import contractCreate from './slices/ContractCreateSlice';
import course from './slices/CourseSlice';
import categoriesList from './slices/CategoriesListSlice';
import studentsListForOrder from './slices/StudentListForOrderSlice';
import teachersListForOrder from './slices/TeachersListForOrderSlice';
import groupStudentsListForOrder from './slices/GroupStudentListForOrderSlice';
import realGroupsForOrder from './slices/RealGroupsForOrderSlice';
import contractCategoriesList from './slices/ContractCategoriesListSlice';
import contractList from './slices/ContractListSlice';
import globalValueList from './slices/GlobalValueListSlice';
import applicationsList from './slices/ApplicationsListSlice';
import usersList from './slices/UsersListSlice';
import applicationsListTableController from './slices/tableController/ApplicationsListTableController';
import usersListTableController from './slices/tableController/UsersListTableController';
import contractListTableController from './slices/tableController/ContractListTableControllerSlice';
import user from './slices/userSlice';
import platformNav from './slices/platform/PlatformNavSlice';
import formSave from './slices/FormSaveSlice';
import form from './slices/FormSlice';
import {contractListTableControllerSlice} from "./slices/tableController/ContractListTableControllerSlice";
export default configureStore({
    reducer: {
        application: application,
        category: category,
        contractCreate: contractCreate,
        course: course,
        categoriesList: categoriesList,
        studentsListForOrder: studentsListForOrder,
        teachersListForOrder: teachersListForOrder,
        groupStudentsListForOrder: groupStudentsListForOrder,
        realGroupsForOrder: realGroupsForOrder,
        contractCategoriesList: contractCategoriesList,
        contractList: contractList,
        globalValueList: globalValueList,
        applicationsList: applicationsList,
        usersList: usersList,
        applicationsListTableController: applicationsListTableController,
        usersListTableController: usersListTableController,
        contractListTableController: contractListTableController,
        user: user,
        platformNav: platformNav,
        formSave: formSave,
        form: form,
    },
});