import React from 'react';
import classes from './Contacts.module.css';
import {Flex, message} from "antd";
import IntlMessage from "../../components/IntlMessage/IntlMessage";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import MyText from "../../components/UI/Text/MyText";

const Contacts = () => {
    return (
        <div>
            <Header/>
            <Flex style={{width: "100%", position: "relative", padding: "120px 20px 20px 20px"}}
                  align={"center"}
                  justify={"center"}>
                <Flex className={classes.blockTemplate} gap={50}
                      style={{width: "100%", maxWidth: 1440, minHeight: "calc(100vh - 300px)", position: "relative"}}
                      vertical
                      align={"flex-start"}
                      justify={"flex-start"}>
                    <MyText uppercase strong size={"large"}><IntlMessage id={'contacts'}/></MyText>
                    <p><IntlMessage id={'contactsPageText1'}/></p>
                    <Flex vertical gap={10} align={"flex-start"} justify={"flex-start"}
                          style={{width: "100%"}} wrap={"wrap"}>
                        <ContactInfo label={<IntlMessage id={'telNumber'}/>} value={'+7 771 325 6171'}/>
                        <ContactInfo label={<IntlMessage id={'office'}/>} value={'г. Астана, Мәңгілік Ел, С1'}/>
                    </Flex>
                </Flex>
            </Flex>
            <Footer/>
        </div>
    );
};

export default Contacts;


const ContactInfo = ({label, value}) => {
    return (
        <Flex vertical gap={10} align={"flex-start"} justify={"flex-start"}
              style={{maxWidth: "300px", width: "100%"}}>
            <p className={classes.contactInfoLabel}>{label}:</p>
            <p className={classes.contactInfoValue}>{value}</p>
        </Flex>
    );
};