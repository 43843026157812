import React, {useEffect, useState} from 'react';
import {useWindowSize} from "@uidotdev/usehooks";
import classes from "./template.module.css";
import aituLogo from "./images/white_logo_aitu.png";
import aitu_half from "./images/half_aitu.png";
import {Flex} from "antd";

const Foundation2023 = ({certificate}) => {
    const [name, setName] = useState(null);
    const [code, setCode] = useState(null);
    const [group, setGroup] = useState(null);
    const [engGrade, setEngGrade] = useState(null);
    const [ictGrade, setIctGrade] = useState(null);

    const size = useWindowSize();

    useEffect(() => {
        setName(certificate?.oldCertCharacs.find(charac => charac.label === '{full_name}')?.value)
        setCode(certificate?.code)
        setGroup(certificate?.oldCertCharacs.find(charac => charac.label === '{group}')?.value)
        setEngGrade(certificate?.oldCertCharacs.find(charac => charac.label === '{english_grade}')?.value)
        setIctGrade(certificate?.oldCertCharacs.find(charac => charac.label === '{ict_grade}')?.value)
    }, [certificate]);

    if (name === null) {
        return null;
    }
    if (code === null) {
        return null;
    }
    if (group === null) {
        return null;
    }
    if (engGrade === null) {
        return null;
    }
    if (ictGrade === null) {
        return null;
    }

    if (group === 'Английский язык + ИКТ') {
        return (
            <>
                <Flex style={{width: "100%", position: "relative", padding: "120px 20px 20px 20px", overflowX: "auto"}}
                      align={"center"}
                      justify={size.width <= 1200 ? "flex-start" : "center"}>
                    <Flex className={classes.blockTemplate} gap={50}
                          style={{width: "100%", maxWidth: 1440, position: "relative", flexDirection: "column"}}
                          vertical align={"center"}
                          justify={"center"}>
                        <div className={classes.gradient2}></div>
                        <div className={classes.uslovniki2032_aituLogoWhite}>
                            <img src={aituLogo} alt=""/>
                        </div>
                        <div className={classes.lager2023_aitu_half_logo}>
                            <img src={aitu_half} alt=""/>
                        </div>
                        <p className={classes.lager2023_certificateTxt}>CERTIFICATE</p>
                        <div className={classes.lager2023_main}>
                            <p style={{fontSize: "2rem", fontWeight: "400"}}>This is to certify that</p>
                            <p className={classes.uslovniki2032_name}
                               style={{fontSize: "2.5rem", fontWeight: "700"}}>{name}</p>
                            <p style={{fontSize: "2rem", fontWeight: "400"}}>completed <span
                                style={{fontWeight: "700"}}>Foundation</span> program, <span
                                style={{fontWeight: "700"}}>ICT</span> course with <span style={{fontWeight: "700"}}>Foundation Exit Test</span> score
                                of <span style={{fontWeight: "700"}}>{ictGrade}</span></p>
                            <Flex vertical gap={20} align={"center"} justify={"space-between"} style={{
                                width: "calc(100% - 100px)",
                                position: "absolute",
                                left: "100px",
                                bottom: "45px",
                            }}>
                                <p style={{fontSize: "1.2rem"}}>{code}</p>
                                <p style={{fontSize: "1.2rem"}}>Astana</p>
                                <p style={{fontSize: "1.2rem", width: "5rem"}}></p>
                            </Flex>
                        </div>
                    </Flex>
                </Flex>
                <Flex style={{width: "100%", position: "relative", padding: "120px 20px 20px 20px", overflowX: "auto"}}
                      align={"center"}
                      justify={size.width <= 1200 ? "flex-start" : "center"}>
                    <Flex className={classes.blockTemplate} gap={50}
                          style={{width: "100%", maxWidth: 1440, position: "relative", flexDirection: "column"}}
                          vertical align={"center"}
                          justify={"center"}>
                        <div className={classes.gradient2}></div>
                        <div className={classes.uslovniki2032_aituLogoWhite}>
                            <img src={aituLogo} alt=""/>
                        </div>
                        <div className={classes.lager2023_aitu_half_logo}>
                            <img src={aitu_half} alt=""/>
                        </div>
                        <p className={classes.lager2023_certificateTxt}>CERTIFICATE</p>
                        <div className={classes.lager2023_main}>
                            <p style={{fontSize: "2rem", fontWeight: "400"}}>This is to certify that</p>
                            <p className={classes.uslovniki2032_name}
                               style={{fontSize: "2.5rem", fontWeight: "700"}}>{name}</p>
                            <p style={{fontSize: "2rem", fontWeight: "400"}}>completed <span
                                style={{fontWeight: "700"}}>Foundation</span> program, <span
                                style={{fontWeight: "700"}}>General English</span> course with <span
                                style={{fontWeight: "700"}}>Foundation Exit Test</span> score of <span
                                style={{fontWeight: "700"}}>{engGrade}</span></p>
                            <Flex vertical gap={20} align={"center"} justify={"space-between"} style={{
                                width: "calc(100% - 100px)",
                                position: "absolute",
                                left: "100px",
                                bottom: "45px",
                            }}>
                                <p style={{fontSize: "1.2rem"}}>{code}</p>
                                <p style={{fontSize: "1.2rem"}}>Astana</p>
                                <p style={{fontSize: "1.2rem", width: "5rem"}}></p>
                            </Flex>
                        </div>
                    </Flex>
                </Flex>
            </>
        )
    }

    if (group === 'ИКТ') {
        return (
            <Flex style={{width: "100%", position: "relative", padding: "120px 20px 20px 20px", overflowX: "auto"}}
                  align={"center"}
                  justify={size.width <= 1200 ? "flex-start" : "center"}>
                <Flex className={classes.blockTemplate} gap={50}
                      style={{width: "100%", maxWidth: 1440, position: "relative", flexDirection: "column"}}
                      vertical align={"center"}
                      justify={"center"}>
                    <div className={classes.gradient2}></div>
                    <div className={classes.uslovniki2032_aituLogoWhite}>
                        <img src={aituLogo} alt=""/>
                    </div>
                    <div className={classes.lager2023_aitu_half_logo}>
                        <img src={aitu_half} alt=""/>
                    </div>
                    <p className={classes.lager2023_certificateTxt}>CERTIFICATE</p>
                    <div className={classes.lager2023_main}>
                        <p style={{fontSize: "2rem", fontWeight: "400"}}>This is to certify that</p>
                        <p className={classes.uslovniki2032_name}
                           style={{fontSize: "2.5rem", fontWeight: "700"}}>{name}</p>
                        <p style={{fontSize: "2rem", fontWeight: "400"}}>completed <span
                            style={{fontWeight: "700"}}>Foundation</span> program, <span
                            style={{fontWeight: "700"}}>ICT</span> course with <span style={{fontWeight: "700"}}>Foundation Exit Test</span> score
                            of <span style={{fontWeight: "700"}}>{ictGrade}</span></p>

                        <Flex vertical gap={20} align={"center"} justify={"space-between"} style={{
                            width: "calc(100% - 100px)",
                            position: "absolute",
                            left: "100px",
                            bottom: "45px",
                        }}>
                            <p style={{fontSize: "1.2rem"}}>{code}</p>
                            <p style={{fontSize: "1.2rem"}}>Astana</p>
                            <p style={{fontSize: "1.2rem", width: "5rem"}}></p>
                        </Flex>
                    </div>
                </Flex>
            </Flex>
        )
    }

    return (
        <Flex style={{width: "100%", position: "relative", padding: "120px 20px 20px 20px", overflowX: "auto"}}
              align={"center"}
              justify={size.width <= 1200 ? "flex-start" : "center"}>
            <Flex className={classes.blockTemplate} gap={50}
                  style={{width: "100%", maxWidth: 1440, position: "relative", flexDirection: "column"}}
                  vertical align={"center"}
                  justify={"center"}>
                <div className={classes.gradient2}></div>
                <div className={classes.uslovniki2032_aituLogoWhite}>
                    <img src={aituLogo} alt=""/>
                </div>
                <div className={classes.lager2023_aitu_half_logo}>
                    <img src={aitu_half} alt=""/>
                </div>
                <p className={classes.lager2023_certificateTxt}>CERTIFICATE</p>
                <div className={classes.lager2023_main}>
                    <p style={{fontSize: "2rem", fontWeight: "400"}}>This is to certify that</p>
                    <p className={classes.uslovniki2032_name} style={{fontSize: "2.5rem", fontWeight: "700"}}>{name}</p>
                    <p style={{fontSize: "2rem", fontWeight: "400"}}>completed <span
                        style={{fontWeight: "700"}}>Foundation</span> program, <span style={{fontWeight: "700"}}>General English</span> course
                        with <span style={{fontWeight: "700"}}>Foundation Exit Test</span> score of <span
                            style={{fontWeight: "700"}}>{engGrade}</span></p>

                    <Flex vertical gap={20} align={"center"} justify={"space-between"} style={{
                        width: "calc(100% - 100px)",
                        position: "absolute",
                        left: "100px",
                        bottom: "45px",
                    }}>
                        <p style={{fontSize: "1.2rem"}}>{code}</p>
                        <p style={{fontSize: "1.2rem"}}>Astana</p>
                        <p style={{fontSize: "1.2rem", width: "5rem"}}></p>
                    </Flex>
                </div>
            </Flex>
        </Flex>
    );
};

export default Foundation2023;