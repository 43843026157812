import React from 'react';
import {Button} from "antd";
import classes from "./buttons.module.css";

const GreyButton = (props) => {
    return (
        <Button {...props} className={classes.greyButton}>
            <span>{props.children}</span>
        </Button>
    );
};

GreyButton.propTypes = {
    ...Button.propTypes, // Use Ant Design Button propTypes
};

export default GreyButton;