import React, {useEffect, useState} from 'react';
import {useWindowSize} from "@uidotdev/usehooks";
import classes from "./template.module.css";
import aituLogo from "./images/white_logo_aitu.png";
import it_summer_logo from "./images/it_summer_camp.png";
import aitu_half from "./images/half_aitu.png";
import {Flex} from "antd";

const CertCourses2023 = ({certificate}) => {
    const [name, setName] = useState(null);
    const [code, setCode] = useState(null);
    const [course, setCourse] = useState(null);
    const [dates, setDates] = useState(null);

    const size = useWindowSize();

    useEffect(() => {
        setName(certificate?.oldCertCharacs.find(charac => charac.label === '{full_name}')?.value)
        setCode(certificate?.code)
        setCourse(certificate?.oldCertCharacs.find(charac => charac.label === '{courses}')?.value)
        setDates(certificate?.oldCertCharacs.find(charac => charac.label === '{dates}')?.value)
    }, [certificate]);
    if (name === null) {
        return null;
    }
    if (code === null) {
        return null;
    }
    if (course === null) {
        return null;
    }
    if (dates === null) {
        return null;
    }
    return (
        <Flex style={{width: "100%", position: "relative", padding: "120px 20px 20px 20px", overflowX: "auto"}}
              align={"center"}
              justify={size.width <= 1200 ? "flex-start" : "center"}>
            <Flex className={classes.blockTemplate} gap={50}
                  style={{width: "100%", maxWidth: 1440, position: "relative", flexDirection: "column"}}
                  vertical align={"center"}
                  justify={"center"}>
                <div className={classes.gradient2}></div>
                <div className={classes.uslovniki2032_aituLogoWhite}>
                    <img src={aituLogo} alt=""/>
                </div>
                <div className={classes.lager2023_aitu_half_logo}>
                    <img src={aitu_half} alt=""/>
                </div>
                <p className={classes.lager2023_certificateTxt}>СЕРТИФИКАТ</p>
                <div className={classes.lager2023_main}>
                    <p style={{fontSize: "1.5rem"}}>Настоящим сертификатом подтверждается, что</p>
                    <p className={classes.uslovniki2032_name}
                       style={{fontSize: "2.2rem", fontWeight: "700"}}>{name}</p>
                    <p style={{fontSize: "1.2rem"}}><span style={{fontWeight: "700"}}>{dates}</span> на базе <span
                        style={{fontWeight: "700"}}>Astana IT University</span> прослушал (ла) курс повышения
                        квалификации <span style={{fontWeight: "700"}}>{course}</span></p>
                    <Flex gap={20} align={"center"} justify={"space-between"} style={{
                        width: "calc(100% - 100px)",
                        position: "absolute",
                        left: "100px",
                        bottom: "45px",
                    }}>
                        <p style={{fontSize: "1.2rem"}}>{code}</p>
                        <p style={{fontSize: "1.2rem"}}>Астана</p>
                        <p style={{fontSize: "1.2rem", width: "5rem"}}></p>
                    </Flex>
                </div>
            </Flex>
        </Flex>
    );
};

export default CertCourses2023;