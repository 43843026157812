import React, {useEffect, useState} from 'react';
import {Flex, Form, Input, message} from "antd";
import {useDispatch, useSelector} from "react-redux";
import BlockLoading from "../../../../../components/LoadingComponents/BlockLoading";
import classes from './createCategory.module.css';
import FormItem from "antd/es/form/FormItem";
import {clrs} from "../../../../../constants/colors";
import ContractService from "../../../../../services/ContractService";
import IntlMessage, {IntlMessageText} from "../../../../../components/IntlMessage/IntlMessage";
import GreyButton from "../../../../../components/UI/Buttons/GreyButton";

const CreateContractCategory = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const nameEng = Form.useWatch('nameEng', form);
    const nameRu = Form.useWatch('nameRu', form);
    const nameKz = Form.useWatch('nameKz', form);

    function handleSubmit() {
        form.validateFields()
            .then(async () => {
                setIsLoading(true);
                await ContractService.saveContractCategory(nameEng, nameRu, nameKz)
                    .then(() => {
                        message.success(<IntlMessage id={'categoryCreated'}/>, 5);
                        form.resetFields();
                    })
                    .catch(() => {
                        message.error(<IntlMessage id={'error'}/>, 5);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            })
            .catch(() => {
                console.log("Validation error!")
            })
    }


    return (
        <Form form={form} className={classes.form}>
            <BlockLoading isLoading={isLoading}/>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'nameEng'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="nameEng">
                <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "nameEng"})} />
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'nameRu'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="nameRu">
                <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "nameRu"})} />
            </FormItem>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'nameKz'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="nameKz">
                <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "nameKz"})} />
            </FormItem>
            <FormItem className={classes.formInputBlock}>
                <Flex align={"flex-start"} justify={"flex-start"} gap={20} style={{width: "100%"}}>
                    <GreyButton onClick={handleSubmit} backgroundColor={clrs.blackerBlue} fontColor={clrs.white}><IntlMessage id={'create'}/></GreyButton>
                </Flex>
            </FormItem>
        </Form>
    );
};

export default CreateContractCategory;