import React, {useEffect, useState} from 'react';
import {Flex, Form, Input, message, Select} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {LocalName} from "../../../../../utils/LocalName";
import IntlMessage, {IntlMessageText} from "../../../../../components/IntlMessage/IntlMessage";
import ContractService from "../../../../../services/ContractService";
import classes from "../../Courses/CreateCourse/CreateCourse.module.css";
import BlockLoading from "../../../../../components/LoadingComponents/BlockLoading";
import FormItem from "antd/es/form/FormItem";
import GreyButton from "../../../../../components/UI/Buttons/GreyButton";
import {clrs} from "../../../../../constants/colors";
import TeacherService from "../../../../../services/TeacherService";

const TeachersCreate = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const fullName = Form.useWatch('fullName', form);
    const categoryId = Form.useWatch('categoryId', form);

    const [categories, setCategories] = useState([]);
    const categoriesList = useSelector((state) => state.categoriesList);

    const [isLoadingCreate, setIsLoadingCreate] = useState(false);

    useEffect(() => {
        setCategories(
            categoriesList.categories.map(category => {
                return { value: category.id, label: LocalName.getName(category) }
            })
        )
    }, [categoriesList.categories]);

    function handleCreate() {
        form.validateFields()
            .then(async () => {
                message.loading(<IntlMessage id={'loading'}/>, 0);
                setIsLoadingCreate(true);
                await TeacherService.saveTeacher(fullName, categoryId)
                    .then(() => {
                        message.destroy();
                        message.success(<IntlMessage id={'creation'}/>, 5);
                        form.resetFields();
                    })
                    .catch(() => {
                        message.destroy();
                        message.error(<IntlMessage id={'error'}/>, 5);
                    })
                    .finally(() => {
                        setIsLoadingCreate(false);
                    })
            })
            .catch(() => {
                console.log("Validation error!")
            })
    }

    return (
        <Form form={form} className={classes.form}>
            <BlockLoading isLoading={isLoadingCreate}/>
            <FormItem rules={[{
                required: true,
                message: <IntlMessage id={'requiredField'}/>,
            },
            ]} label={<IntlMessage id={'fullName'}/>} labelCol={{ span: 24 }} className={classes.formInputBlock} name="fullName">
                <Input rootClassName={classes.formInput} placeholder={IntlMessageText.getText({id: "fullName"})} />
            </FormItem>
            <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                      className={classes.formInput} style={{width: '100%', padding: 0}} name="categoryId"
                      label={<IntlMessage id={'categoryCourse'}/>} labelCol={{ span: 24 }}
            >
                <Select options={categories} placeholder={IntlMessageText.getText({id: "categoryCourse"})}/>
            </FormItem>
            <FormItem className={classes.formInputBlock}>
                <Flex align={"flex-start"} justify={"flex-start"} gap={20} style={{width: "100%"}}>
                    <GreyButton onClick={handleCreate} backgroundColor={clrs.blackerBlue} fontColor={clrs.white}><IntlMessage id={'create'}/></GreyButton>
                </Flex>
            </FormItem>
        </Form>
    );
};

export default TeachersCreate;