import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import ContractService from "../../services/ContractService";

let initialState = {
    data: null,
    isLoading: false,
    error: null,
}

export const saveContract = createAsyncThunk(
    'saveContract',
    async ({firstName, lastName, middleName, categoryId}, {getState, rejectWithValue, dispatch}) => {
        try {
            const response = await ContractService.saveContract(firstName, lastName, middleName, categoryId);
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

const contractCreateSlice = createSlice({
    name: 'contractCreateSlice',
    initialState,
    reducers: {
        resetContractCreateState(state) {
            state.data = null;
            state.isLoading = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveContract.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.data = null;
            })
            .addCase(saveContract.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload.data;
            })
            .addCase(saveContract.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});

export const {resetContractCreateState} = contractCreateSlice.actions;

export default contractCreateSlice.reducer;