import {ACCESS_TOKEN} from "../constants/auth";
import {API_BASE_URL} from "../constants/api";
import axios from "axios";

const ApplicationService = {};
ApplicationService.saveApplication = async function (name, email, telNumber, categoryId) {
    let data = new FormData();
    data.append("name", name);
    data.append("email", email);
    data.append("telNumber", telNumber);
    data.append("categoryId", categoryId);

    return axios({
        method: "post",
        url: API_BASE_URL + "/api/application/save",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
}
ApplicationService.getAllApplicationsPageable = async function (page, limit, applicationStatus, categoryId, fullName) {
    if (applicationStatus === undefined) {
        applicationStatus = null;
    }
    if (categoryId === undefined) {
        categoryId = null;
    }
    if (fullName === undefined) {
        fullName = null;
    }
    const response = await axios({
        method: "get",
        url: API_BASE_URL + `/api/admin/application/get`,
        params: {
            page: page - 1,
            limit: limit,
            status: applicationStatus,
            categoryId: categoryId,
            fullName: fullName,
        },
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
    const data = response.data.list;
    const hasMore = response.data.hasMore;
    return { data, hasMore };
}

ApplicationService.getAllApplicationsByStatusPageable = async function (page, limit, status) {
    const response = await axios({
        method: "get",
        url: API_BASE_URL + `/api/admin/application/get?page=${page - 1}&limit=${limit}&status=${status}`,
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
    const data = response.data.list;
    const hasMore = response.data.hasMore;
    return { data, hasMore };
}

ApplicationService.rejectApplicationById = async function (id) {
    return axios({
        method: "post",
        url: API_BASE_URL + `/api/admin/application/${id}/reject`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

ApplicationService.deleteApplicationsById = async function (ids) {
    let data = new FormData();
    data.append("IDs", ids);
    return axios({
        method: "delete",
        url: API_BASE_URL + `/api/admin/application/delete/multiple`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}


ApplicationService.commentApplicationsById = async function (ids, comment) {
    let data = new FormData();
    data.append("IDs", ids);
    data.append("comment", comment ? comment : " ");
    return axios({
        method: "post",
        url: API_BASE_URL + `/api/admin/application/comment/multiple`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

ApplicationService.notifyApplicationsById = async function (ids, subject, message) {
    let data = new FormData();
    data.append("IDs", ids);
    data.append("subject", subject);
    data.append("message", message);
    return axios({
        method: "post",
        url: API_BASE_URL + `/api/admin/application/newsletter/notify-form/multiple`,
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        }
    });
}

export default ApplicationService;