import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import {Button, Flex, Form, Input, message, Select, Steps, Tabs} from "antd";
import IntlMessage, {IntlMessageText} from "../../../../../components/IntlMessage/IntlMessage";
import HorizontalDivider from "../../../../../components/Divider/HorizontalDivider";
import {useDispatch, useSelector} from "react-redux";
import {LocalName} from "../../../../../utils/LocalName";
import classes from "../../Applications/application.module.css";
import FormItem from "antd/es/form/FormItem";
import MyText from "../../../../../components/UI/Text/MyText";
import OrderService from "../../../../../services/OrderService";
import TeacherService from "../../../../../services/TeacherService";
import {clrs} from "../../../../../constants/colors";
import {getAllApplicationsPageable} from "../../../../../store/slices/ApplicationsListSlice";
import cl from '../order.module.css';
import studentsListForOrder, {getAllStudentsForOrder} from "../../../../../store/slices/StudentListForOrderSlice";
import {addStudent, remoteStudent} from "../../../../../store/slices/GroupStudentListForOrderSlice";
import {getAllTeachersForOrder} from "../../../../../store/slices/TeachersListForOrderSlice";
import {addGroups} from "../../../../../store/slices/RealGroupsForOrderSlice";

const GetStep3GroupContent = memo(({groupName, form}) => {
    const {teachers} = useSelector((state) => state.teachersListForOrder);

    form.setFieldValue("group." + groupName + ".time.kz", "Дүйсенбі, сейсенбі, \nсәрсенбі, бейсенбі, \nжұма, сенбі, \nжексенбі \n16:00 - 18.15");
    form.setFieldValue("group." + groupName + ".time.ru", "Понедельник, \nвторник, среда, \nчетверг, пятница, \nсуббота, \nвоскресенье \n16:00 - 18.15");
    form.setFieldValue("group." + groupName + ".duration", "14.06.2024 - 29.06.2024");
    return (
        <Flex style={{width: "100%", position: "relative"}} gap={10} vertical>
            <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                      className={classes.formInputBlock} name={"group." + groupName + ".teacher"}
                      label={<IntlMessage id={'ordersPage.teacher'}/>}
                      labelCol={{ span: 24 }}
            >
                <Select options={teachers} />
            </FormItem>
            <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                      className={classes.formInputBlock} name={"group." + groupName + ".duration"}
                      label={<IntlMessage id={'ordersPage.duration'}/>}
                      labelCol={{ span: 24 }}
            >
                <Input rootClassName={classes.formInput} />
            </FormItem>
            <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                      className={classes.formInputBlock} name={"group." + groupName + ".time.kz"}
                      label={<IntlMessage id={'ordersPage.time'}/>}
                      labelCol={{ span: 24 }}
            >
                <Input.TextArea rootClassName={classes.formInput} autoSize={{ minRows: 6, maxRows: 10 }}/>
            </FormItem>
            <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                      className={classes.formInputBlock} name={"group." + groupName + ".time.ru"}
                      label={<IntlMessage id={'ordersPage.time'}/>}
                      labelCol={{ span: 24 }}
            >
                <Input.TextArea rootClassName={classes.formInput} autoSize={{ minRows: 6, maxRows: 10 }}/>
            </FormItem>
        </Flex>
    )
})
const GetStep4GroupContent = memo(({groupName, form}) => {

    const searchFieldRef = useRef(null);

    const dispatch = useDispatch();

    const {students} = useSelector((state) => state.studentsListForOrder);
    const {groupStudents} = useSelector((state) => state.groupStudentsListForOrder);
    const {teachers} = useSelector((state) => state.teachersListForOrder);
    const {realGroups} = useSelector((state) => state.realGroupsForOrder);

    const listBlockStyle = {
        width: "100%",
        padding: 15,
        borderRadius: 10,
        backgroundColor: clrs.white,
        height: "100%",
    }

    async function handleSearchStudents() {
        // message.loading(<IntlMessage id={'loading'}/>, 0);
        let categoryId = form.getFieldValue("categoryId");
        let searchStudent = form.getFieldValue("searchStudent");
        dispatch(getAllStudentsForOrder({categoryId, searchStudent}));
        searchFieldRef.current.focus();
    }

    function addStudentToGroup(student, group) {
        if (groupStudents.find(groupStudent => groupStudent?.student.id === student.id)) {
            message.warning(<IntlMessage id={'ordersPage.addStudentError'}/>, 5);
        } else {
            dispatch(addStudent({
                group: group,
                student: student
            }))
        }

    }
    function removeStudentFromGroup(groupStudent) {
        dispatch(remoteStudent(groupStudent))
    }

    return (
        <Flex style={{width: "100%", position: "relative"}} gap={30} align={"flex-start"} justify={"center"}>
            <Flex style={{width: "50%", minHeight: 500, position: "relative"}} gap={20} align={"center"} justify={"flex-start"} vertical>
                <MyText size={"small"} strong>
                    <IntlMessage id={'search'}/>
                </MyText>
                <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                          className={classes.formInputBlock} name={"searchStudent"}
                >
                    <Input ref={searchFieldRef} rootClassName={classes.formInput} placeholder={"ФИО"} onKeyUp={(e) => {if (e.key === "Enter") {
                        handleSearchStudents()
                    }}}/>
                </FormItem>
                <Flex style={listBlockStyle}  gap={20} align={"center"} justify={"flex-start"} vertical>
                    {
                        students.map((student, i) => {
                            if (!groupStudents.find(groupStudent => groupStudent.student.id === student.id)) {
                                return (
                                    <MyText onClick={() => {addStudentToGroup(student, groupName)}} size={"small"} className={cl.studentListItem}>
                                        {student.fullName}
                                    </MyText>
                                )
                            }
                        })
                    }
                </Flex>
            </Flex>
            <Flex style={{width: "50%", minHeight: 500, position: "relative"}} gap={20} align={"center"} justify={"flex-start"} vertical>
                <MyText size={"small"} strong>
                    <IntlMessage id={'ordersPage.students'}/>
                    <strong>{groupName}</strong>
                </MyText>
                <Flex style={listBlockStyle} gap={20} align={"center"} justify={"flex-start"} vertical>
                    {
                        groupStudents.map((groupStudent, i) => {
                            if (groupStudent.group === groupName) {
                                return (
                                    <MyText onClick={() => {removeStudentFromGroup(groupStudent)}} size={"small"} className={cl.studentListItem}>
                                        {i + 1}. {groupStudent.student.fullName}
                                    </MyText>
                                )
                            }
                        })
                    }
                </Flex>
            </Flex>
        </Flex>
    )
})

const OrderCreate = () => {

    const dispatch = useDispatch();

    const [form] = Form.useForm();
    const categoryId = Form.useWatch('categoryId', form);
    const groups = Form.useWatch('groups', form);

    // const searchStudent = Form.useWatch('searchStudent', form);
    // const [students, setStudents] = useState([]); //
    // const [groupStudents, setGroupStudents] = useState([]); //

    const [currentStep, setCurrentStep] = useState(0);

    const [categories, setCategories] = useState([]);

    const categoriesList = useSelector((state) => state.categoriesList);
    const {students} = useSelector((state) => state.studentsListForOrder);
    const {groupStudents} = useSelector((state) => state.groupStudentsListForOrder);
    const {teachers} = useSelector((state) => state.teachersListForOrder);
    const {realGroups} = useSelector((state) => state.realGroupsForOrder);

    // const [realGroups, setRealGroups] = useState([]); //
    // const [teachers, setTeachers] = useState([]);

    useEffect(() => {
        setCategories(
            categoriesList.categories.map((category) => {
                return {value: category.id, label: LocalName.getName(category)};
            })
        );
    }, [categoriesList.categories]);

    function isNextStepAvailable(nextStep) {
        if (currentStep === 0) {
            if (categoryId === null) {
                message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                return false;
            }
            if (categoryId === undefined) {
                message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                return false;
            }
        }
        if (currentStep === 1) {
            if (groups === undefined) {
                message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                return false;
            }
            if (groups === null) {
                message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                return false;
            }
            if (groups.length === 0) {
                message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                return false;
            }
            for (const group of groups) {
                if (form.getFieldValue("group." + group + ".quantity") === undefined) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
                if (form.getFieldValue("group." + group + ".quantity") === null) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
                if (form.getFieldValue("group." + group + ".quantity") <= 0) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
            }
        }
        if (currentStep === 2) {
            for (const groupName of realGroups) {
                if (form.getFieldValue("group." + groupName + ".teacher") === undefined) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
                if (form.getFieldValue("group." + groupName + ".teacher") === null) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
                if (form.getFieldValue("group." + groupName + ".time.kz") === undefined) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
                if (form.getFieldValue("group." + groupName + ".time.kz") === null) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
                if (form.getFieldValue("group." + groupName + ".time.kz").length === 0) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
                if (form.getFieldValue("group." + groupName + ".time.ru") === undefined) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
                if (form.getFieldValue("group." + groupName + ".time.ru") === null) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
                if (form.getFieldValue("group." + groupName + ".time.ru").length === 0) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
                if (form.getFieldValue("group." + groupName + ".duration") === undefined) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
                if (form.getFieldValue("group." + groupName + ".duration") === null) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
                if (form.getFieldValue("group." + groupName + ".duration").length === 0) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
            }
        }
        if (currentStep === 3) {
            for (const groupName of realGroups) {
                if (!groupStudents.find(groupStudent => groupStudent.group === groupName)) {
                    message.warning(<IntlMessage id={'ordersPage.validateStepError'}/>, 5);
                    return false;
                }
            }
        }
        return true;
    }

    async function getRealGroups() {
        message.loading(<IntlMessage id={'loading'}/>, 0);
        let newRealGroups = [];
        for (const group of groups) {
            await OrderService.getNextGroupNumber(group)
                .then((r) => {
                    let startNumber = r.data;
                    let groupQuantity = form.getFieldValue("group." + group + ".quantity");
                    for (let j = 0; j < groupQuantity; j++) {
                        let groupNumber = startNumber + j;
                        newRealGroups.push(`${group} ${groupNumber}`);
                    }
                })
                .catch((r) => {

                })
        }


        dispatch(getAllTeachersForOrder({categoryId: form.getFieldValue("categoryId")}))

        form.setFieldValue("searchStudent");
        dispatch(getAllStudentsForOrder({
            categoryId: form.getFieldValue("categoryId"),
            searchStudent: form.getFieldValue("searchStudent")
        }))

        message.destroy();
        dispatch(addGroups(newRealGroups));
        setCurrentStep(2);
    }



    async function handleCreateOrder() {
        message.loading(<IntlMessage id={'loading'}/>, 0);

        const body = {
            "groups": [],
            "categoryId": form.getFieldValue("categoryId")
        }

        for (const group of realGroups) {
            let studentContractIds = [];
            for (const groupStudent of groupStudents) {
                if (groupStudent.group === group) {
                    studentContractIds.push(groupStudent.student.id)
                }
            }

            let numbers = group.match(/\d/g);
            const groupBody = {
                "name": group,
                "number": Number(numbers.join('')),
                "duration": form.getFieldValue("group." + group + ".duration"),
                "timeKz": form.getFieldValue("group." + group + ".time.kz").replaceAll("\n", "<br/>"),
                "timeRu": form.getFieldValue("group." + group + ".time.ru").replaceAll("\n", "<br/>"),
                "studentContractIds": studentContractIds,
                "teacherId": form.getFieldValue("group." + group + ".teacher")
            }
            body.groups.push(groupBody)
        }

        await OrderService.saveOrder(body)
            .then((r) => {
                message.destroy();
                message.success(<IntlMessage id={'success'}/>, 5);
                console.log(r);
                form.resetFields();
                setCurrentStep(0);
            })
            .catch((r) => {
                message.destroy();
                message.error(<IntlMessage id={'error'}/>, 5);
            })
    }

    const groupOptions = [
        {
            value: "Pre-Intermediate",
            label: "Pre-Intermediate",
        },
        {
            value: "Intermediate",
            label: "Intermediate",
        }
    ]

    const items = [
        {
            key: 1,
            title: <IntlMessage id={'category'}/>,
            disabled: currentStep > 1
        },
        {
            key: 2,
            title: <IntlMessage id={'ordersPage.groups'}/>,
            disabled: currentStep > 1
        },
        {
            key: 3,
            title: <IntlMessage id={'ordersPage.groups.2'}/>
        },
        {
            key: 4,
            title: <IntlMessage id={'ordersPage.students'}/>
        },
        {
            key: 5,
            title: <IntlMessage id={'ordersPage.overview'}/>
        },
    ]

    const steps = [
        {
            key: 1,
            content: <>
                <Flex style={{width: "100%", position: "relative"}} vertical>
                    <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                              className={classes.formInput} style={{width: '100%', padding: 0}} name="categoryId">
                        <Select options={categories} placeholder={IntlMessageText.getText({id: "categoryCourse"})}/>
                    </FormItem>
                </Flex>
            </>,
        },
        {
            key: 2,
            content: <>
                <Flex style={{width: "100%", position: "relative"}} vertical gap={20}>
                    <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                              className={classes.formInput} style={{width: '100%', padding: 0}} name="groups">
                        <Select mode="tags" placeholder="Pre-Intermediate" options={groupOptions}/>
                    </FormItem>
                    <MyText size={"small"}>
                        <IntlMessage id={'ordersPage.groupQuantity'}/>:
                    </MyText>
                    {
                        groups?.map((group, i) => {
                            return (
                                <Flex key={new Date() + i} gap={20} align={"center"} justify={"flex-start"}>
                                    <MyText strong size={"small"}>{group}:</MyText>
                                    <FormItem rules={[{required: true, message: <IntlMessage id={'requiredField'}/>}]}
                                              className={classes.formInputBlock} style={{maxWidth: 150}} name={"group." + group + ".quantity"}>
                                        <Input rootClassName={classes.formInput} size={"small"} type={"number"}/>
                                    </FormItem>
                                </Flex>
                            )
                        })
                    }
                </Flex>
            </>,
        },
        {
            key: 3,
            content: <>
                <Flex style={{width: "100%", position: "relative"}} vertical>
                    <Tabs
                        type="card"
                        defaultActiveKey={"1"}
                        tabPosition={"top"}
                        items={realGroups.map((group, i) => {
                            return {
                                label: group,
                                key: new Date() + group + i,
                                children: <GetStep3GroupContent groupName={group} form={form}/>
                            };
                        })}
                    />
                </Flex>
            </>,
        },
        {
            key: 4,
            content: <>
                <Flex style={{width: "100%", position: "relative"}} vertical>
                    <Tabs
                        type="card"
                        defaultActiveKey={"1"}
                        tabPosition={"top"}
                        items={realGroups.map((group, i) => {
                            return {
                                label: group,
                                key: new Date() + group + i + i,
                                children: <GetStep4GroupContent groupName={group} form={form}/>
                            };
                        })}
                    />
                </Flex>
            </>,
        },
        {
            key: 5,
            content: <>
                <Flex style={{width: "100%", position: "relative"}} align={"center"} justify={"center"} vertical>
                    <Button onClick={handleCreateOrder} type={"primary"}>
                        <IntlMessage id={'create'}/>
                    </Button>
                </Flex>
            </>,
        },
    ];

    return (
        <Flex style={{width: "100%", position: "relative", minWidth: 800}} gap={20} vertical>
            <Steps onChange={(value) => {
                if (value > currentStep + 1) {
                    message.warning(<IntlMessage id={'ordersPage.stepError'}/>, 5);
                } else {
                    if (currentStep > 1 && value < 2) {
                        message.warning(<IntlMessage id={'ordersPage.step.1.2.Error'}/>, 5);
                    } else {
                        if (value === currentStep + 1) {
                            if (currentStep == 1) {
                                getRealGroups();
                            } else {
                                if (isNextStepAvailable(value)) {
                                    setCurrentStep(value)
                                }
                            }

                        } else {
                            setCurrentStep(value)
                        }
                    }
                }
            }} current={currentStep} items={items} labelPlacement={"vertical"} size={"small"}/>
            <HorizontalDivider style={{backgroundColor: "#e5e5e5"}}/>
            <Form form={form} className={classes.form}>
                {steps[currentStep].content}
            </Form>
        </Flex>
    );
};

export default OrderCreate;